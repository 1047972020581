.container {
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
	flex-grow: 1;

	@media (min-width: $sm-width) {
		max-width: 620px;
	}
	@media (min-width: $md-width) {
		max-width: 720px;
	}
	@media (min-width: $lg-width) {
		max-width: 940px;
	}
}
