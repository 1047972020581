$colors: (
	"info": (
		"bg": $info-1,
		"icon": $validation,
		"border": $new,
	),
	"warning": (
		"bg": $warning-1,
		"icon": $warning-2,
		"border": $warning-0,
	),
);

.info-box {
	display: flex;
	align-content: flex-start;
	padding: $padding-xs;
	border-radius: 6px;
	min-height: $padding-lg;
	box-sizing: border-box;

	.text-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0 $padding-xs;

		.MuiTypography-root {
			color: $gray-4;
		}
	}

	@each $color, $values in $colors {
		&.#{$color} {
			background-color: rgba(map-get($values, "bg"), 0.25);
			border: 1px solid map-get($values, "border");
			color: map-get($values, "icon");
		}
	}
}
