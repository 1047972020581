.request-create {
	.delete-button {
		position: absolute;
		top: 5px;
		right: 5px;
	}

	.MuiStepper-root {
		margin-bottom: $padding-sm;
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;

		.requestid-grid-root {
			margin-bottom: $padding-xs;

			.requestid-content-root {
				display: flex;
				align-items: center;

				.request-deleted-text {
					color: $danger-0;
				}
			}
		}

		.title-container {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			color: $gray-4;
			align-self: flex-start;
		}

		.initial-items-container {
			margin-top: $padding-xs;

			.item {
				.header {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;

					.MuiTypography-body1 {
						font-weight: 700;
					}
				}

				ul {
					margin: 0;
					padding: 0;

					li {
						margin: $padding-xxs 0;
						list-style-type: none;

						&::before {
							content: "";
							width: 10px;
							height: 10px;
							display: inline-block;
							vertical-align: baseline;
							margin-right: $padding-xs;
							border-radius: 50%;
							background-color: $secondary-1;
						}
					}
				}

				.icon-header {
					margin-left: $padding-xs;
					font-size: 20px;
				}

				.icon-root {
					text-align: center;
				}

				i {
					font-size: 86px;
					margin: $padding-sm 0;
				}
			}
		}

		.create-private {
			.MuiTypography-subtitle2 {
				margin-bottom: $padding-sm;
			}

			.MuiButton-root {
				margin-top: $padding-lg;
				align-self: center;
			}
		}

		.prevalidation-item-root {
			&.MuiListItem-root {
				padding: $padding-sm $padding-xxs;

				.title-row {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;

					.status-row {
						display: flex;
						flex-direction: row-reverse;
						align-items: center;

						.status-container {
							display: flex;
							justify-content: center;
							.icon {
								font-size: 20px;
								margin-right: $padding-xs;
							}
						}
					}
				}

				.MuiListItemText-secondary {
					margin-top: $padding-xs;
				}

				.icon-placeholder {
					margin: $padding-sm $padding-xs 6px $padding-xs;
				}

				&.genre-item-root {
					.MuiListItemText-root {
						.MuiListItemText-secondary {
							margin-left: 30px;
						}
					}
				}
			}
		}

		.padded-list {
			@media (min-width: $md-width) {
				.MuiListItem-root:not(:first-child) {
					margin-top: 36px;
				}
			}
		}

		.gallery-container {
			.gallery.vertical {
				.MuiList-root {
					margin-left: -$padding-xs;
					margin-right: -$padding-xs;
				}
			}
		}

		.facebook-info-container {
			.status-row {
				display: flex;
				flex-grow: 1;
				flex-direction: row-reverse;
				align-items: center;
				margin-top: $padding-sm;
				margin-bottom: $padding-xs;

				&.FacebookIdStatus {
					.status-container {
						margin-top: 18px;
					}
				}

				&.AdvertIdStatus {
					margin-bottom: 25px;
				}

				.content {
					flex-grow: 1;
					align-items: flex-start;
				}

				.status-container {
					margin-right: $padding-sm;
					display: flex;
					align-items: center;
					position: relative;

					.icon {
						font-size: 22px;
					}

					.MuiCircularProgress-root {
						margin: 0;
					}
				}
			}

			.check-button-row {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				margin: $padding-sm 0 $padding-xs 0;

				.button-container {
					margin-left: $padding-sm;
				}
			}

			.info-block {
				width: 100%;
				margin-bottom: $padding-md;

				.MuiAccordion-root {
					padding: $padding-sm $padding-xs;
				}

				.MuiTypography-h5 {
					margin: $padding-sm 0 $padding-xs 0;
				}

				.margin {
					margin-bottom: $padding-sm;
				}
			}

			.wizard-container {
				margin-top: $padding-md;
				margin-right: -$padding-xxs / 2;
				margin-left: -$padding-xxs / 2;

				.wizard-step-container {
					padding: $padding-xxs;
					flex-grow: 1;

					.wizard-step {
						box-sizing: border-box;
						height: 100%;
						min-height: 300px;
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						border: 1px solid $secondary-0;
						border-radius: 6px;
						padding: $padding-sm;
						&.disabled {
							pointer-events: none;
							opacity: 0.4;
						}

						.invisible {
							visibility: hidden;
						}

						.status-container {
							margin-top: 0;
							margin-right: $padding-xs;
						}

						.wizard-step-title {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							margin-bottom: $padding-sm;

							.wizard-status-info {
								display: flex;
								width: 100%;

								.status-row {
									margin: 0;
								}
							}
						}

						.wizard-step-content {
							display: flex;
							flex-direction: column;
							margin-bottom: $padding-xs;
							height: 100%;

							a:last-child {
								margin-top: auto;

								.actionable-content {
									margin-bottom: unset;
								}
							}

							.actionable-content {
								display: flex;
								padding: $padding-xxs;
								word-break: break-word;
								text-align: left;
								align-items: flex-start;
								border-radius: $padding-sm;
								border: 0;
								margin-bottom: $padding-xs;

								&:hover {
									background-color: $gray-1;
								}

								.MuiButton-label {
									text-transform: none;
									justify-content: flex-start;

									.MuiTypography-root {
										color: inherit;
									}
								}
								.MuiButton-startIcon {
									margin: 0;
								}
								.icon {
									margin: $padding-xxs $padding-xs 0 0;

									/* &.icon-link {
										margin: 0 $padding-xs 0 0;
										font-size: 24px;
									} */
								}

								@each $name, $color in $normal-colors {
									&.#{$name} {
										color: $color;
									}
								}
							}
						}

						.standart-button {
							.progress {
								color: $white;
							}
						}

						.description {
							margin: 0 0 $padding-xxs 0;
						}

						&.question-step {
							margin-bottom: $padding-sm;
							min-height: unset;

							.wizard-step-content {
								margin: unset;
							}

							.actions {
								display: flex;
								margin-top: $padding-sm;
								justify-content: space-evenly;
								flex-wrap: wrap;
								margin-right: -$padding-xs;
								margin-left: -$padding-xs;

								.standart-button {
									max-width: 300px;
									flex-shrink: 1;
									flex-grow: 1;
									margin-top: $padding-sm;
									margin-right: $padding-xs;
									margin-left: $padding-xs;
									margin-bottom: unset;

									.MuiButton-label {
										white-space: normal;
									}

									@media (max-width: $md-width) {
										max-width: unset;
									}
								}
							}
						}
					}

					.continue {
						margin: 0;
						width: 100%;
						height: 100%;
						border-radius: 6px;
						text-decoration: underline;

						&:hover {
							background-color: $gray-1;
						}
					}
				}
			}

			.options-container {
				margin-top: 0;

				.option-card {
					padding: $padding-sm;
				}

				.option-card-content {
					min-height: 0;
					.header {
						text-align: center;
						.icon {
							color: currentColor;
						}
					}

					.body {
						display: flex;
						flex-direction: row;
						align-items: flex-start;
						justify-content: space-between;
						text-align: center;
						margin-bottom: $padding-sm;

						@media (max-width: $sm-width) {
							flex-direction: column;
							align-items: center;
						}

						.MuiBox-root {
							margin: $padding-xs $padding-xxs;

							.icon {
								font-size: 86px;
								display: block;
								margin: $padding-sm 0 $padding-xxs 0;
							}
						}
					}
				}
			}
		}

		.app-name-link-container {
			display: flex;
			margin-top: $padding-md;

			@media (max-width: $md-width) {
				flex-direction: column-reverse;
				align-items: center;
				flex-grow: 1;
			}

			.MuiBox-root {
				flex-grow: 1;
				margin-right: $padding-md;
				@media (max-width: $md-width) {
					margin: $padding-sm 0 $padding-md;
				}
			}

			.gallery {
				&.vertical {
					.MuiList-root {
						margin: 0;

						.MuiListItem-root {
							margin: 0;
						}
					}
				}
			}
		}

		.MuiCircularProgress-root {
			margin-left: $padding-xs;
			color: $gray-3;
		}

		.pre-validation-container {
			.icon-info1 {
				font-size: 22px;
				color: $secondary-0;
				margin-right: $padding-xs;
			}

			.app-name-container {
				margin-top: $padding-md;
			}

			.multi-columns {
				.MuiGrid-item {
					width: 100%;
					margin-top: $padding-xs;

					.MuiTypography-subtitle1 {
						color: $black;
					}

					.MuiListItem-button {
						border-radius: 4px;
						&:hover {
							background-color: $white;
						}
						.MuiTouchRipple-root {
							color: $gray-2;
						}

						.status-row {
							.status-container {
								display: flex;
								justify-content: center;
								.icon {
									font-size: 20px;
									margin-right: $padding-xs;
								}
							}
						}
					}
				}

				.icon-arrow {
					font-size: 22px;
					color: $secondary-0;
				}

				.developer-link-root {
					margin: 0 $padding-xxs;

					&:only-child {
						margin-top: $padding-sm;

						@media (max-width: $md-width) {
							margin-top: unset;
						}
					}
				}
			}
		}

		.block-container {
			margin-bottom: $padding-sm;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			border: 1px solid $secondary-0;
			border-radius: 6px;
			padding: $padding-xs;

			&.first-block {
				margin-top: $padding-md;
			}

			.platform-switch {
				flex-shrink: 0;

				.standart-button {
					padding: 12px 0;
					width: 62px;
				}

				i {
					font-size: 24px;
				}
			}

			.block-header {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;

				.status-row {
					display: flex;
					flex-direction: row-reverse;
					align-items: center;

					.content {
						margin-left: $padding-xxs;
					}

					.status-container {
						height: $padding-sm;
					}
				}
			}

			.store-link-container {
				display: flex;
				justify-content: space-between;
				width: 100%;
				margin-bottom: $padding-xxs;
			}

			.block-content {
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				width: 100%;
				padding-left: $h5-size;

				.standart-button {
					margin-top: 0;
					margin-bottom: 0;
				}

				&.store-link {
					.url-text {
						max-width: fit-content;
					}

					.store-link-info-container {
						min-width: 0;
					}

					.store-link-buttons-container {
						display: flex;
						flex-direction: column;
						justify-content: flex-end;
						margin-left: $padding-xs;
						width: 200px;

						& > *:first-child:not(:only-child) {
							margin-bottom: $padding-xs;
						}
					}
				}

				&.icon-and-name {
					.gallery {
						margin-right: $padding-sm;
						ul {
							margin: 0;
							.MuiListItem-root {
								margin: 0;
							}
						}
					}

					.MuiButton-root {
						min-width: 200px;
						width: 200px;
						padding: 8px 14px;

						.MuiButton-label {
							white-space: normal;
							text-align: left;

							.MuiButton-startIcon {
								margin-left: 0;
							}
						}

						&.Mui-disabled {
							&.MuiButton-contained {
								background-color: rgba($secondary-0, 0.7);
							}
						}

						.MuiCircularProgress-root {
							margin-left: 0;
						}
						i {
							font-size: 24px;
						}
					}

					.fullwidth-content {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						width: 100%;

						.MuiButton-root {
							align-self: flex-end;
						}
					}
				}
			}
		}

		.genre-select {
			margin-bottom: $padding-sm;
		}
	}

	.buttons-container {
		margin-top: $padding-md;
		display: flex;
		justify-content: space-between;
		width: 100%;

		.standart-button {
			margin: 0;

			&.justIcon {
				margin: 0 0 0 $padding-sm;
			}

			&:only-child {
				margin-left: auto;
			}
		}

		@media (max-width: $md-width) {
			display: flex;
			align-items: center;
			flex-direction: column-reverse;
			align-self: center;
			margin-top: $padding-xs;

			.standart-button {
				margin: $padding-xs 0;
			}
		}
	}
}
