.placeholder-view {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;

	img {
		margin: $padding-sm 0;
	}

	.MuiTypography-root {
		text-align: center;
		color: $gray-3;
		padding-bottom: $padding-sm;

		.icon {
			font-size: 32px;
			padding-bottom: 2px;
		}
	}

	.heading {
		color: $gray-4;
	}
}
