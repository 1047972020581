.landing-placeholder {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	background-position: center top;
	background-size: cover;

	.video {
		position: absolute;
		height: 100%;
		min-width: 100%;

		@media (max-width: $md-width) {
			display: none;
		}

		video {
			height: 100%;
			min-width: 100%;
			min-height: 56.25vw; /* 100 * 9 / 16 */
		}
		&.filter {
			&:after {
				background: rgba(0, 0, 0, 0.5);
				position: absolute;
				z-index: 0;
				width: 100%;
				height: 100%;
				display: block;
				left: 0;
				top: 0;
				content: "";
			}
		}
	}

	&.filter {
		&:before {
			background: rgba(0, 0, 0, 0.5);
			position: absolute;
			width: 100%;
			height: 100%;
			display: block;
			left: 0;
			top: 0;
			content: "";
		}
	}

	.content {
		z-index: 1;
		height: 100vh;
		width: 100vw;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.container {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			background-color: $white;
			padding: 55px;
			border-radius: 25px;
			flex-grow: 0;
			position: relative;

			@media (max-width: $md-width) {
				flex-direction: column;
				border-radius: 17px;
				padding: 25px;
			}

			.top-circle {
				width: 120px;
				height: 120px;
				border-radius: 50%;
				background: $secondary-0;
				position: absolute;
				top: -60px;
				left: -60px;
				z-index: -1;

				@media (max-width: $md-width) {
					width: 86px;
					height: 86px;
					top: -43px;
					left: -43px;
				}
			}

			.logo {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: center;
				margin-right: 55px;

				@media (max-width: $md-width) {
					margin-bottom: 55px;
					margin-right: 0;
				}

				img {
					&.image {
						margin-bottom: 18px;

						@media (max-width: $md-width) {
							width: 105px;
						}
					}

					&.name {
						@media (max-width: $md-width) {
							width: 105px;
						}
					}
				}
			}

			.email-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				background-color: $white;

				@media (max-width: $md-width) {
					align-items: center;
				}

				.MuiTypography-root {
					color: $secondary-0;
					text-align: left;

					@media (max-width: $md-width) {
						text-align: center;
					}

					&.MuiTypography-h4 {
						margin-bottom: 35px;
						font-weight: 700;

						@media (max-width: $md-width) {
							font-size: 20px;
							line-height: 30px;
							margin-bottom: 48px;
						}
					}

					&.MuiTypography-subtitle2 {
						line-height: 34px;
						font-size: 25px;

						@media (max-width: $md-width) {
							line-height: 16px;
							font-size: 12px;
						}
					}

					&.MuiTypography-h5 {
						text-decoration: underline;

						@media (max-width: $md-width) {
							line-height: 20px;
							font-size: 14px;
						}
					}
				}
			}
		}

		.footer {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 55px;
			background: $secondary-0;
			position: relative;

			@media (max-width: $md-width) {
				height: 40px;
			}

			padding: 0 $padding-xs;

			.copyrights {
				color: $white;
				text-align: center;

				@media (max-width: $md-width) {
					font-size: 14px;
					line-height: 20px;
				}
			}

			.bottom-circle {
				width: 120px;
				height: 120px;
				position: absolute;
				top: -97px;
				right: -18px;
				z-index: -1;

				@media (max-width: $md-width) {
					width: 96px;
					height: 96px;
					top: -76px;
					right: -14px;
				}
			}
		}
	}
}
