.game-info {
	.standart-button {
		box-sizing: border-box;
		min-width: unset;
		width: 100%;
		margin: 0;
		padding: $padding-xs $padding-md;
	}

	.title-container {
		display: flex;
		margin-bottom: $padding-md;
	}

	.game-info-container {
		display: flex;
		align-items: center;
		.game-icon {
			margin-right: $padding-sm;
			border-radius: 6px;
		}

		.rating-and-bundle-container {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;

			.rating {
				display: flex;
				align-items: center;
			}

			.bundle-id {
				display: flex;
				align-items: center;

				i.icon {
					font-size: 24px;
					margin-right: $padding-xs;
					&.icon-apple {
						margin-bottom: $padding-xxs;
					}
				}
			}
		}
	}

	.game-links-container {
		a {
			display: block;
			width: 100%;
			margin-top: $padding-xs;
		}
	}

	.settings-container {
		margin-top: $padding-sm;
		border: 1px solid $secondary-0;
		border-radius: 6px;
		padding: $padding-xs $padding-sm;
		justify-content: flex-end;

		.MuiGrid-item:nth-child(1) {
			margin-bottom: $padding-xs;
		}

		.MuiGrid-item:nth-child(2) {
			padding-right: $padding-sm;
			margin-bottom: $padding-sm;
		}

		.save-button-container {
			align-self: flex-end;
			display: flex;
		}
	}

	.game-stats-container {
		margin-top: $padding-md;
		background-color: $white;

		.MuiTabs-root {
			position: relative;
			z-index: 2;
			box-shadow: 0px 4px 4px 0px #00000040;

			.MuiTabs-flexContainer {
				button {
					flex-grow: 1;
					max-width: unset;
					min-height: 60px;

					.MuiTab-wrapper {
						@extend .MuiTypography-subtitle1;
						color: $gray-3;
					}

					&.Mui-selected {
						.MuiTab-wrapper {
							color: $secondary-0;
						}
					}
				}
			}

			.MuiTabs-indicator {
				background-color: $secondary-0;
			}
		}

		div[role="tabpanel"] {
			overflow-x: auto;

			.placeholder-view {
				background-color: $gray-0;
				height: 630px;
				width: 100%;

				img {
					margin-top: $padding-lg;
					max-width: 100%;
				}

				.standart-button {
					margin-top: $padding-md;
					width: 300px;
				}
			}

			.MuiTable-root {
				table-layout: fixed;
				min-width: 500px;

				.MuiTableCell-root {
					height: 70px;
					padding: 0 5px;
					text-align: center;
					background-color: $white;
				}

				.MuiTableHead-root {
					.MuiTableCell-root {
						height: 60px;
						padding: $padding-xxs;
						text-align: center;
					}

					.Spend,
					.AdRevenue,
					.Roas,
					.RetentionD1 {
						width: 18%;
					}

					.EditSpend {
						width: 120px;
					}
					.Value,
					.Day {
						width: 20%;
					}
				}

				.MuiTableBody-root {
					.MuiTableRow-root {
						&:nth-child(odd) {
							.MuiTableCell-root {
								background-color: rgba($gray-1, 0.75);
							}
						}

						&.highlighted {
							.MuiTableCell-root {
								background-color: rgba($success-0, 0.1);
							}
							&:nth-child(odd) {
								.MuiTableCell-root {
									background-color: rgba($success-1, 0.25);
								}
							}
						}

						&.interactive:hover {
							cursor: pointer;
							.MuiTableCell-root {
								background-color: $info-1;
							}
						}

						.MuiTableCell-root {
							padding: 0;
							box-sizing: border-box;

							&.comment-cell {
								text-align: left;
								word-break: break-word;
							}

							&.edit-spend-cell {
								.edit-button {
									color: $success-0;
								}

								.delete-button {
									color: $danger-0;
								}

								.MuiCircularProgress-root {
									margin-top: $padding-xxs;
									color: $gray-3;
								}
							}
						}
					}
				}

				.MuiTableFooter-root {
					.MuiTableCell-footer {
						background-color: $gray-0;
						border: none;

						.table-actions {
							display: flex;
							justify-content: space-between;
							overflow-x: auto;

							.standart-button {
								width: unset;
								height: fit-content;
							}

							.MuiTablePagination-root {
								min-width: 250px;
								.MuiToolbar-root {
									min-height: unset;
									padding-left: 0;
									padding-right: $padding-sm;

									.MuiTablePagination-spacer {
										order: 2;
									}

									.MuiTablePagination-caption {
										@extend .MuiTypography-body2;
										color: $gray-4;
									}
								}
							}
						}
					}
				}
			}

			&.stats-panel,
			&.expenses-panel {
				position: relative;

				.placeholder-view {
					position: absolute;
					z-index: 1;
				}
			}
		}
	}
}
