.MuiFormLabel-root {
	@extend .MuiTypography-body1;
	color: $gray-4;
}
.MuiFormLabel-root.Mui-focused {
	color: $primary-0;
}
.MuiFormLabel-root.Mui-disabled {
	color: $gray-3;
}
.MuiFormLabel-root.Mui-error {
	color: $error-main;
}
.MuiFormLabel-colorSecondary.Mui-focused {
	color: $secondary-0;
}
.MuiFormLabel-asterisk,
.Mui-error,
.asterisk-error {
	color: $error-main;
}

.MuiInputBase-input {
	@extend .MuiTypography-body1;
	height: auto;
}

.MuiInput-colorSecondary.MuiInput-underline:after {
	border-bottom-color: $secondary-0;
}
.MuiInput-underline:after {
	border-bottom: 2px solid $primary-1;
}
.MuiInput-underline.Mui-error:after {
	border-bottom-color: $error-main;
}
.MuiInput-underline:before {
	border-bottom: 1px solid $gray-2;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: 2px solid $gray-2;
}
.MuiInput-underline.Mui-disabled:before {
	border-bottom-style: dotted;
}
@media (hover: none) {
	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-bottom: 1px solid $gray-2;
	}
}

.MuiFormHelperText-root {
	color: $gray-3;
	margin: 4px 0 -20px 0;
	font-size: $caption-size;
	text-align: left;
	font-weight: 400;
	line-height: $caption-line;
	letter-spacing: 0.03333em;
}

//вводимый текст
.MuiInput-formControl {
	@extend .MuiTypography-body1;
	color: $gray-4;
}

//плейсхолдер
.MuiInput-input {
	&:placeholder-shown {
		@extend .MuiTypography-body1;
		color: $gray-3;
	}
}

// Лейбл без фокуса
.MuiInputLabel-root {
	@extend .MuiTypography-body1;
	color: $gray-3;
}

// Лейбл в фокусе
.MuiInputLabel-shrink {
	@extend .MuiTypography-body2;
	margin-bottom: 6px;
}

.MuiFormHelperText-root.Mui-disabled {
	color: $gray-2;
}
.MuiFormHelperText-root.Mui-error {
	color: $error-main;
}

.MuiFormControl-marginNormal {
	margin-top: $padding-sm;
	margin-bottom: $padding-sm;
}

.MuiFormControl-marginDense {
	margin-top: 8px;
	margin-bottom: 4px;
}

&.withCloseButton .MuiDialog-paper .MuiDialogContent-root .MuiInput-input:placeholder-shown {
	margin-bottom: 0;
}
