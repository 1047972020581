.go-back-button {
	color: $success-0;
	font-size: 30px;
	border-radius: 6px;
	padding: 0;
	margin-right: $padding-xs;

	&:hover {
		background-color: $gray-1;
	}
}
