.MuiButton-root {
	color: $black;
	padding: 14px 21px;
	font-size: $body2-size;
	font-family: $font;
	font-weight: 400;
	line-height: $body2-line;
	border-radius: 10px;
	text-transform: uppercase;

	&.Mui-disabled {
		color: $gray-2;
	}
}

.MuiButton-text {
	padding: 7px 10px;
	text-transform: uppercase;
}

.MuiButton-textPrimary {
	color: $primary-0;

	&:hover {
		background-color: rgba($primary-0, 0.04);
	}
}

.MuiButton-textSecondary {
	color: $secondary-0;

	&:hover {
		background-color: rgba($secondary-0, 0.04);
	}
}

.MuiButton-outlined {
	padding: 13px 20px;
	text-transform: uppercase;
}
.MuiButton-outlined.Mui-disabled {
	border: 1px solid $gray-2;
}
.MuiButton-outlinedPrimary {
	color: $primary-0;
	border: 1px solid $primary-0;
}
.MuiButton-outlinedPrimary:hover {
	border: 1px solid $primary-0;
	background-color: rgba($primary-0, 0.04);
}
.MuiButton-outlinedSecondary {
	color: $secondary-0;
	border: 1px solid $secondary-0;
}
.MuiButton-outlinedSecondary:hover {
	border: 1px solid $secondary-0;
	background-color: rgba($secondary-0, 0.04);
}
.MuiButton-outlinedSecondary.Mui-disabled {
	border: 1px solid $gray-2;
}
.MuiButton-contained {
	text-transform: uppercase;
}
.MuiButton-contained.Mui-disabled {
	color: $gray-2;
	box-shadow: none;
	background-color: $gray-2;
}
.MuiButton-contained:hover.Mui-disabled {
	background-color: $gray-2;
}
.MuiButton-containedPrimary {
	color: $white;
	background-color: $primary-0;
}
.MuiButton-containedPrimary:hover {
	background-color: $primary-2;
}
@media (hover: none) {
	.MuiButton-containedPrimary:hover {
		background-color: $primary-0;
	}
}
.MuiButton-containedSecondary {
	color: $white;
	background-color: $secondary-0;
}
.MuiButton-containedSecondary:hover {
	background-color: $secondary-2;
}
@media (hover: none) {
	.MuiButton-containedSecondary:hover {
		background-color: $secondary-0;
	}
}
.MuiButton-textSizeSmall {
	padding: 4px 5px;
	font-size: 0.8125rem;
}
.MuiButton-textSizeLarge {
	padding: 21px;
	font-size: 0.9375rem;
}
.MuiButton-outlinedSizeSmall {
	padding: 3px 9px;
	font-size: 0.8125rem;
}
.MuiButton-outlinedSizeLarge {
	padding: 20px;
	font-size: 0.9375rem;
}
.MuiButton-containedSizeSmall {
	padding: 4px 10px;
	font-size: 0.8125rem;
}
.MuiButton-containedSizeLarge {
	padding: 21px;
	font-size: 0.9375rem;
}
.MuiButton-sizeLarge {
	font-size: $body1-size;
	font-weight: 400;
	line-height: $body1-line;
}
.MuiButton-sizeSmall {
	font-size: $caption-size;
	font-weight: 400;
	line-height: $caption-line;
	box-sizing: border-box;
	border-radius: 6px;
}
.MuiButton-fullWidth {
	width: 100%;
}
.MuiButton-startIcon {
	display: inherit;
	margin-left: -4px;
	margin-right: 8px;
}
.MuiButton-startIcon.MuiButton-iconSizeSmall {
	margin-left: -2px;
}
.MuiButton-endIcon {
	display: inherit;
	margin-left: 8px;
	margin-right: -4px;
}
.MuiButton-endIcon.MuiButton-iconSizeSmall {
	margin-right: -2px;
}
.MuiButton-iconSizeSmall > *:first-child {
	font-size: 18px;
}
.MuiButton-iconSizeMedium > *:first-child {
	font-size: $body1-size;
}
.MuiButton-iconSizeLarge > *:first-child {
	font-size: $h5-size;
	margin-top: -2px;
	margin-bottom: -2px;
}

.MuiIconButton-root {
	color: $gray-3;
}
.MuiIconButton-colorPrimary {
	color: $primary-0;
}
.MuiIconButton-colorPrimary:hover {
	background-color: rgba($primary-0, 0.04);
}
.MuiIconButton-colorSecondary {
	color: $secondary-0;
}
.MuiIconButton-colorSecondary:hover {
	background-color: rgba($secondary-0, 0.04);
}
.MuiIconButton-colorInherit {
	color: inherit;
}
