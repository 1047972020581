.MuiChip-root {
	font-size: $caption-size;
	font-family: $font;
}
.MuiChip-root .MuiChip-avatarColorPrimary {
	color: $white;
	background-color: $primary-2;
}
.MuiChip-root .MuiChip-avatarColorSecondary {
	color: $white;
	background-color: $secondary-2;
}
.MuiChip-root .MuiChip-avatarSmall {
	font-size: $caption-size;
}
.MuiChip-colorPrimary {
	color: $white;
	background-color: $primary-0;
}
.MuiChip-colorSecondary {
	color: $white;
	background-color: $secondary-0;
}
.MuiChip-clickable:hover,
.MuiChip-clickable:focus {
	background-color: $gray-0;
}
.MuiChip-clickableColorPrimary:hover,
.MuiChip-clickableColorPrimary:focus {
	background-color: $primary-2;
}
.MuiChip-clickableColorSecondary:hover,
.MuiChip-clickableColorSecondary:focus {
	background-color: $secondary-1;
}
.MuiChip-deletable:focus {
	background-color: $gray-0;
}
.MuiChip-deletableColorPrimary:focus {
	background-color: $primary-1;
}
.MuiChip-deletableColorSecondary:focus {
	background-color: $secondary-1;
}
.MuiChip-outlined {
	border: 1px solid $gray-3;
	background-color: transparent;
}
.MuiChip-clickable.MuiChip-outlined:hover,
.MuiChip-clickable.MuiChip-outlined:focus,
.MuiChip-deletable.MuiChip-outlined:focus {
	background-color: $gray-4;
}
.MuiChip-outlinedPrimary {
	color: $primary-0;
	border: 1px solid $primary-0;
}
.MuiChip-clickable.MuiChip-outlinedPrimary:hover,
.MuiChip-clickable.MuiChip-outlinedPrimary:focus,
.MuiChip-deletable.MuiChip-outlinedPrimary:focus {
	background-color: rgba($primary-0, 0.04);
}
.MuiChip-outlinedSecondary {
	color: $secondary-0;
	border: 1px solid $secondary-0;
}
.MuiChip-clickable.MuiChip-outlinedSecondary:hover,
.MuiChip-clickable.MuiChip-outlinedSecondary:focus,
.MuiChip-deletable.MuiChip-outlinedSecondary:focus {
	background-color: rgba($secondary-0, 0.04);
}
.MuiChip-icon {
	color: $gray-4;
}
.MuiChip-deleteIcon {
	color: $gray-3;
}
.MuiChip-deleteIcon:hover {
	color: $gray-4;
}
.MuiChip-deleteIconColorPrimary {
	color: rgba($white, 0.7);
}
.MuiChip-deleteIconColorPrimary:hover,
.MuiChip-deleteIconColorPrimary:active {
	color: $white;
}
.MuiChip-deleteIconColorSecondary {
	color: rgba($white, 0.7);
}
.MuiChip-deleteIconColorSecondary:hover,
.MuiChip-deleteIconColorSecondary:active {
	color: $white;
}
.MuiChip-deleteIconOutlinedColorPrimary {
	color: $primary-0;
}
.MuiChip-deleteIconOutlinedColorPrimary:hover,
.MuiChip-deleteIconOutlinedColorPrimary:active {
	color: $primary-0;
}
.MuiChip-deleteIconOutlinedColorSecondary {
	color: $secondary-0;
}
.MuiChip-deleteIconOutlinedColorSecondary:hover,
.MuiChip-deleteIconOutlinedColorSecondary:active {
	color: $secondary-0;
}
