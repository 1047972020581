.lang-picker,
.lang-picker-item {
	&.MuiFormControl-root {
		img {
			margin-left: $padding-xs;
		}
	}

	img {
		margin-right: $padding-xs;
	}
	.MuiTypography-body1 {
		color: inherit;
	}
}
