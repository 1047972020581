.MuiIcon-colorPrimary {
	color: $primary-0;
}
.MuiIcon-colorSecondary {
	color: $secondary-0;
}
.MuiIcon-colorAction {
	color: $black;
}
.MuiIcon-colorError {
	color: $error-main;
}
