.time-selector {
	.day-part-container {
		.label {
			font-size: 17px;
			line-height: 22px;
			letter-spacing: -0.41px;
			color: $gray-2;
		}

		.button-list {
			.MuiButton-root {
				margin: 5px 5px 0 0;
			}

			.empty {
				font-size: 17px;
				line-height: 22px;
				letter-spacing: -0.41px;
				color: $gray-2;
			}
		}
	}
}
