.option-card {
	box-sizing: border-box;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: $white;
	padding: $padding-sm 50px;
	text-transform: inherit;
	align-items: flex-start;
	text-align: left;

	&:hover,
	&:focus {
		box-shadow: 0 14px 26px -12px rgba($gray-4, 0.42), 0 4px 23px 0px rgba($black, 0.12),
			0 8px 10px -5px rgba($gray-4, 0.2);
		.button-next {
			background-color: $success-0;
			color: $white;
		}
	}

	&.Mui-disabled {
		box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
			0px 1px 3px 0px rgba(0, 0, 0, 0.12);
		opacity: 0.7;
	}

	i {
		color: $secondary-0;
	}

	.option-card-content {
		display: flex;
		flex-direction: column;
		min-height: 350px;
		align-items: center;
		height: 100%;
		width: 100%;
	}

	.MuiTypography-body1 {
		color: $gray-4;
		font-size: 16px;
		line-height: 22px;
	}

	&.secondary {
		background-color: $secondary-0;

		.MuiTypography-body1 {
			color: $white;
		}
	}

	.button-next {
		height: 55px;
		display: flex;
		align-self: center;
		align-items: center;
		justify-content: center;
		border: 1px solid $success-0;
		text-transform: uppercase;
		border-radius: 3px;
		color: $success-0;

		.MuiCircularProgress-root {
			color: inherit !important;
			margin-right: $padding-xxs;
		}

		.MuiTypography-body1 {
			color: inherit;
		}
	}

	.MuiButton-label {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
}
