.express-register-view {
	display: flex;
	align-items: center;
	flex-direction: column;

	.social-auth {
		margin-bottom: $padding-sm;
	}

	.infoArea {
		width: 100%;
	}

	.icon-wrap-info-area {
		margin-right: $padding-sm;
	}

	.express-register-form {
		width: 100%;

		.MuiFormControl-root {
			width: 100%;
			flex-direction: column;

			.MuiSwitchBarLabel {
				align-self: flex-start;
				margin-bottom: $padding-xs;
				color: $gray-4;
			}

			.MuiButtonGroup-root {
				justify-content: center;

				.MuiButton-root {
					flex: 1;
					height: $padding-md;
					width: 150px;
				}
			}
		}

		.icon-info {
			font-size: 22px;
			color: $secondary-0;
			margin-right: 12px;
		}

		.request-type-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			.MuiFormControl-root {
				width: auto;
			}

			.icon-info-outline {
				color: $secondary-0;
				font-size: 20px;
				margin-left: $padding-xs;
			}
		}

		.info-box-height {
			height: $padding-lg;
		}
	}

	.submitButton {
		align-self: center;
	}
}
