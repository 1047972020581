.landing-view {
	.MuiGrid-container {
		margin-right: -15px;
		margin-left: -15px;
		width: auto;
	}

	h5 {
		color: $secondary-0;
	}

	.MuiGrid-item {
		position: relative;
		width: 100%;
		min-height: 1px;
		padding-right: 15px;
		padding-left: 15px;
	}

	.cardCategory {
		margin-top: 10px;
	}

	.textLeft {
		text-align: left;
	}

	.info-container {
		padding-top: $padding-md;
	}
}
