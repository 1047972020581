.social-auth {
	.buttons {
		padding-top: $padding-sm;
		padding-bottom: $padding-sm;

		.icon {
			font-size: 32px;
			padding-bottom: 2px;
		}
	}
}
