.standart-info-block {
	margin-bottom: 6px;
	display: flex;
	flex-direction: column;
	align-items: center;

	.texts-margin {
		margin-bottom: $padding-sm;
	}

	h5 {
		margin-bottom: $padding-md;
	}

	h6 {
		margin-top: 8px;
		margin-bottom: 16px;
	}
}
