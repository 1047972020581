&.calendar-popover-root {
	.MuiPaper-root {
		background-color: $white;

		.MuiPickersDay-day {
			.MuiTypography-root {
				color: $gray-4;
			}
			&.MuiPickersDay-daySelected {
				background-color: $secondary-0;
				.MuiTypography-root {
					color: $white;
				}
			}

			&.MuiPickersDay-current {
				border: 1px solid $gray-3;
			}

			&.MuiPickersDay-dayDisabled {
				.MuiTypography-root {
					color: $gray-3;
				}
			}
		}
	}
}
