.genre-list {
	position: relative;

	.info-button {
		position: absolute;
		top: 0;
		right: 0;
	}

	.select-input {
		&.with-info-button {
			margin-top: 24px;
		}
	}
}
