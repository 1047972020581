// Переменные, используемые в стилях
@import "./scss/variables.scss";

// Иконки из проекта icomoon
@import "./scss/icons.scss";

// Общие стили приложения
@import "./scss/app-theme/styles.scss";
@import "./scss/common.scss";
@import "./scss/mui-theme/styles.scss";
@import "./scss/dialogs/styles.scss";

// Стили отдельных компонентов
@import "./scss/components/styles.scss";
@import "./scss/datepicker.scss";
@import "./scss/time-selector.scss";
@import "./scss/user-menu.scss";
@import "./scss/time-range-selector.scss";
@import "./scss/notification-card.scss";
@import "./scss/gallery.scss";
@import "./scss/drawer.scss";
@import "./scss/landing-placeholder.scss";
@import "./scss/circular-with-label.scss";
@import "./scss/ctr-landing-view.scss";

//Стили для страниц
.app-container .page-content {
	@import "./scss/home-view.scss";
	@import "./scss/components-view.scss";
	@import "./scss/requests-list.scss";
	@import "./scss/landing-view.scss";
	@import "./scss/signup-view.scss";
	@import "./scss/express-register-view.scss";
	@import "./scss/request-create.scss";
	@import "./scss/upsert-developer-form.scss";
	@import "./scss/request-view.scss";
	@import "./scss/request-info.scss";
	@import "./scss/publisher-login-view.scss";
	@import "./scss/publisher-view.scss";
	@import "./scss/doc-view.scss";
	@import "./scss/not-found-view.scss";
	@import "./scss/help-view.scss";
	@import "./scss/pro-dashboard.scss";
	@import "./scss/game-info-view.scss";
}
