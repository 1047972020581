@font-face {
	font-family: "icomoon";
	src: url("fonts/icomoon.eot?ihqigu");
	src: url("fonts/icomoon.eot?ihqigu#iefix") format("embedded-opentype"),
		url("fonts/icomoon.ttf?ihqigu") format("truetype"), url("fonts/icomoon.woff?ihqigu") format("woff"),
		url("fonts/icomoon.svg?ihqigu#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icomoon" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-user-6-1:before {
	content: "\e944";
}
.icon-arrow-3:before {
	content: "\e93f";
}
.icon-puzzle:before {
	content: "\e90a";
}
.icon-roll_down:before {
	content: "\e900";
}
.icon-roll_up:before {
	content: "\e903";
}
.icon-close:before {
	content: "\e908";
}
.icon-agreement:before {
	content: "\e96f";
}
.icon-e-mail:before {
	content: "\e96c";
}
.icon-message:before {
	content: "\e96d";
}
.icon-tt:before {
	content: "\e96b";
}
.icon-google-play:before {
	content: "\e96a";
}
.icon-review:before {
	content: "\e967";
}
.icon-rejected:before {
	content: "\e969";
}
.icon-return:before {
	content: "\e968";
}
.icon-turn-left:before {
	content: "\e966";
}
.icon-calendar:before {
	content: "\e965";
}
.icon-tenjin:before {
	content: "\e963";
}
.icon-ironsource:before {
	content: "\e964";
}
.icon-icon-pencil:before {
	content: "\e962";
}
.icon-settingsdk:before {
	content: "\e95f";
}
.icon-settingsdk1:before {
	content: "\e960";
}
.icon-icon-sdkstatistik:before {
	content: "\e95e";
}
.icon-filter:before {
	content: "\e95d";
}
.icon-left-parenthesis:before {
	content: "\e942";
}
.icon-right-parenthesis:before {
	content: "\e955";
}
.icon-pause:before {
	content: "\e956";
}
.icon-home:before {
	content: "\e957";
}
.icon-play-1:before {
	content: "\e958";
}
.icon-next:before {
	content: "\e95b";
}
.icon-previous:before {
	content: "\e95c";
}
.icon-gamepad-2:before {
	content: "\e952";
}
.icon-gamepad-1:before {
	content: "\e94b";
}
.icon-video-2:before {
	content: "\e94c";
}
.icon-video-1:before {
	content: "\e94d";
}
.icon-request-list:before {
	content: "\e941";
}
.icon-coffee-2:before {
	content: "\e959";
}
.icon-coffee-1:before {
	content: "\e95a";
}
.icon-Unity:before {
	content: "\e950";
}
.icon-video:before {
	content: "\e951";
}
.icon-sdk-1:before {
	content: "\e953";
}
.icon-statistics:before {
	content: "\e954";
}
.icon-Yellow:before {
	content: "\e902";
}
.icon-Green:before {
	content: "\e94f";
}
.icon-Red:before {
	content: "\e94e";
}
.icon-sdk:before {
	content: "\e94a";
}
.icon-attention:before {
	content: "\e949";
}
.icon-game_underconstruction:before {
	content: "\e948";
}
.icon-create-ad-strok:before {
	content: "\e945";
}
.icon-edit-video:before {
	content: "\e946";
}
.icon-create-ad:before {
	content: "\e947";
}
.icon-left:before {
	content: "\e943";
}
.icon-file-5:before {
	content: "\e940";
}
.icon-link1:before {
	content: "\e93e";
}
.icon-stoped:before {
	content: "\e93c";
}
.icon-arrow-1:before {
	content: "\e939";
}
.icon-connection:before {
	content: "\e918";
}
.icon-training:before {
	content: "\e928";
}
.icon-value:before {
	content: "\e934";
}
.icon-cancel:before {
	content: "\e93d";
}
.icon-serch:before {
	content: "\e93a";
}
.icon-settings:before {
	content: "\e93b";
}
.icon-crop:before {
	content: "\e938";
}
.icon-arrow:before {
	content: "\e937";
}
.icon-info1:before {
	content: "\e92c";
}
.icon-cloud:before {
	content: "\e936";
}
.icon-add1:before {
	content: "\e92b";
}
.icon-Checkboxes:before {
	content: "\e92a";
}
.icon-Checkboxhec:before {
	content: "\e935";
}
.icon-check:before {
	content: "\e92d";
}
.icon-completed:before {
	content: "\e92e";
}
.icon-error:before {
	content: "\e92f";
}
.icon-new:before {
	content: "\e930";
}
.icon-pencil:before {
	content: "\e931";
}
.icon-comment:before {
	content: "\e932";
}
.icon-testing:before {
	content: "\e933";
}
.icon-analytics-acesses1:before {
	content: "\e927";
}
.icon-analytics-statys1:before {
	content: "\e911";
}
.icon-android:before {
	content: "\e929";
}
.icon-circle:before {
	content: "\e924";
}
.icon-analytics-acesses:before {
	content: "\e925";
}
.icon-analytics-statys:before {
	content: "\e926";
}
.icon-badly:before {
	content: "\e921";
}
.icon-good:before {
	content: "\e922";
}
.icon-satisfactorily:before {
	content: "\e923";
}
.icon-info-outline:before {
	content: "\e915";
}
.icon-copy:before {
	content: "\e920";
}
.icon-download:before {
	content: "\e910";
}
.icon-telegram:before {
	content: "\e90c";
}
.icon-apple:before {
	content: "\e90e";
}
.icon-info:before {
	content: "\e90f";
}
.icon-Twitter:before {
	content: "\e90d";
}
.icon-mail:before {
	content: "\e916";
}
.icon-user:before {
	content: "\e917";
}
.icon-do:before {
	content: "\e919";
}
.icon-link:before {
	content: "\e91a";
}
.icon-time:before {
	content: "\e91b";
}
.icon-Logotip:before {
	content: "\e91c";
}
.icon-Facebook:before {
	content: "\e91d";
}
.icon-Google:before {
	content: "\e91e";
}
.icon-Instagram:before {
	content: "\e91f";
}
.icon-website-1:before {
	content: "\e913";
}
.icon-website-2:before {
	content: "\e912";
}
.icon-analyze-1:before {
	content: "\e914";
}
.icon-file_apk:before {
	content: "\e90b";
}
.icon-game_underconstruction1:before {
	content: "\e961";
}
.icon-add:before {
	content: "\e909";
}
.icon-play:before {
	content: "\e907";
}
.icon-game_ready:before {
	content: "\e901";
}
.icon-video_size:before {
	content: "\e904";
}
.icon-chronometer-1:before {
	content: "\e905";
}
.icon-delit:before {
	content: "\e906";
}
