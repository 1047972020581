.parallax {
	height: 90vh;
	min-height: 800px;
	max-height: 1000px;
	overflow: hidden;
	position: relative;
	background-position: center top;
	background-size: cover;
	margin: 0;
	padding: 0;
	border: 0;
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: -60px;

	@media (max-width: $sm-width) {
		align-items: flex-start;
		padding-top: 150px;
		min-height: 750px;
	}

	.video {
		position: absolute;
		z-index: -1;
		height: 100%;
		min-width: 100%;

		@media (max-width: $md-width) {
			display: none;
		}

		video {
			height: 100%;
			min-width: 100%;
			min-height: 56.25vw; /* 100 * 9 / 16 */
		}
		&.filter {
			&:after {
				background: rgba(0, 0, 0, 0.5);
				position: absolute;
				z-index: 0;
				width: 100%;
				height: 100%;
				display: block;
				left: 0;
				top: 0;
				content: "";
			}
		}
	}

	&.filter {
		&:before {
			background: rgba(0, 0, 0, 0.5);
			position: absolute;
			z-index: -1;
			width: 100%;
			height: 100%;
			display: block;
			left: 0;
			top: 0;
			content: "";
		}
	}
	&.small {
		height: 380px;
	}

	.content {
		max-width: 75%;

		@media (max-width: $xl-width) {
			max-width: 100%;
		}

		.title {
			color: $white;
			margin-bottom: 40px;
		}

		.description {
			color: $white;
			margin-bottom: 40px;
		}

		.standart-button {
			font-weight: bold;
		}
	}
}

@media (min-aspect-ratio: 16/9) {
	#videoContainer > video {
		height: 300%;
		top: -100%;
	}
}
@media (max-aspect-ratio: 16/9) {
	#videoContainer > video {
		width: 300%;
		left: -100%;
	}
}
@supports (object-fit: cover) {
	#videoContainer > video {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}
