.components {
	.brand {
		color: $white;
		text-align: left;
	}
	.title {
		font-size: 4.2rem;
		font-weight: 600;
		display: inline-block;
		position: relative;
	}
	.subtitle {
		font-size: 1.313rem;
		max-width: 500px;
		margin: 10px 0 0;
	}
	.main {
		background: $gray-0;
		position: relative;
		z-index: 3;
	}
	.mainRaised {
		margin: -60px 30px 0px;
		border-radius: 6px;
		box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
			0 8px 10px -5px rgba(0, 0, 0, 0.2);
	}
	.link {
		text-decoration: none;
	}
	.textCenter {
		text-align: center;
	}
}
