.standart-button {
	min-height: auto;
	min-width: auto;
	background-color: $gray-4;
	color: $white;
	box-shadow: 0 2px 2px 0 rgba($gray-4, 0.14), 0 3px 1px -2px rgba($gray-4, 0.2), 0 1px 5px 0 rgba($gray-4, 0.12);
	border: none;
	border-radius: 3px;
	position: relative;
	padding: 12px 30px;
	margin: 0.3125rem 1px;
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 0;
	transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	line-height: 1.42857143;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;

	&:hover,
	&:focus {
		color: $white;
		background-color: $gray-4;
		box-shadow: 0 14px 26px -12px rgba($gray-4, 0.42), 0 4px 23px 0px rgba($black, 0.12),
			0 8px 10px -5px rgba($gray-4, 0.2);
	}

	&.fab,
	&.fas,
	&.far,
	&.fal,
	&.material-icons {
		position: relative;
		display: inline-block;
		top: 0;
		margin-top: -1em;
		margin-bottom: -1em;
		font-size: 1.1rem;
		margin-right: 4px;
		vertical-align: middle;
	}

	&svg {
		position: relative;
		display: inline-block;
		top: 0;
		width: 18px;
		height: 18px;
		margin-right: 4px;
		vertical-align: middle;
	}

	&.justIcon {
		padding-left: 12px;
		padding-right: 12px;
		font-size: 20px;
		height: 41px;
		min-width: 41px;
		width: 41px;

		&.fab,
		&.fas,
		&.far,
		&.fal,
		&.material-icons,
		&.icon {
			margin-top: 0px;
			margin-right: 0px;
			position: absolute;
			width: 100%;
			transform: none;
			left: 0px;
			top: 0px;
			height: 100%;
			line-height: 41px;
			font-size: 20px;
		}
	}

	&.round {
		border-radius: 30px;
	}

	&.MuiButton-sizeLarge {
		padding: 1.125rem 2.25rem;
		font-size: 0.875rem;
		line-height: 1.333333;
		border-radius: 0.2rem;

		&.justIcon {
			height: 57px;
			min-width: 57px;
			width: 57px;
			line-height: 56px;
			padding: 1.125rem;
			font-size: $h5-size;

			&.fab,
			&.fas,
			&.far,
			& .fal,
			& svg,
			&.material-icons {
				width: 32px;
				height: 32px;
				font-size: 32px;
			}
		}
		&.round {
			border-radius: 30px;
		}
	}

	&.MuiButton-sizeSmall {
		padding: 0.40625rem 1.25rem;
		font-size: 0.6875rem;
		line-height: 1.5;
		border-radius: 0.2rem;

		&.justIcon {
			height: 30px;
			min-width: 30px;
			width: 30px;

			&.fab,
			&.fas,
			&.far,
			& .fal,
			& svg,
			&.material-icons {
				margin-top: 1px;
			}
		}
		&.round {
			border-radius: 30px;
		}
	}

	@each $name, $color in $normal-colors {
		$shadow-color: $color;
		@if $name == "white" {
			$shadow-color: $gray-4;
		}

		&.#{$name} {
			@if $name == "secondary" {
				color: rgba($white, 0.87);
			}
			@if $name == "white" {
				color: $gray-4;
			}
			background-color: $color;
			box-shadow: 0 2px 2px 0 rgba($shadow-color, 0.14), 0 3px 1px -2px rgba($shadow-color, 0.2),
				0 1px 5px 0 rgba($shadow-color, 0.12);
			&:hover,
			&:focus {
				@if $name == "secondary" {
					color: rgba($white, 0.87);
				}
				background-color: $color;
				box-shadow: 0 14px 26px -12px rgba($shadow-color, 0.42), 0 4px 23px 0px rgba($black, 0.12),
					0 8px 10px -5px rgba($shadow-color, 0.2);
			}
			&.Mui-disabled {
				&.MuiButton-contained {
					background-color: rgba($color, 0.5);
					color: $white;
				}
				&.MuiButton-outlined {
					border-color: rgba($color, 0.5);
					color: rgba($color, 0.5);
				}
			}
			&.MuiButton-disableElevation {
				box-shadow: none;
			}

			&.pulse {
				animation: Pulse-#{$name} 2s infinite ease-in-out;
			}
		}
	}

	&.transparent {
		box-shadow: none;
		&:focus,
		&:hover {
			color: inherit;
			background: transparent;
			box-shadow: none;
		}
	}

	&.MuiButton-text {
		color: $gray-4;
		background: transparent;
		box-shadow: none;

		&:focus,
		&:hover {
			color: $gray-4;
			background: transparent;
			box-shadow: none;
		}

		&.Mui-disabled {
			color: rgba($gray-4, 0.5);
		}

		@each $name, $color in $normal-colors {
			&.#{$name} {
				color: $color;

				&:focus,
				&:hover,
				&:visited {
					color: $color;
				}

				&.Mui-disabled {
					color: rgba($color, 0.5);
				}
			}
		}
	}

	&.MuiButton-outlined {
		color: $gray-4;
		background: transparent;
		box-shadow: none;
		border: 1px solid $gray-4;
		padding: 11px 29px;

		&.MuiButton-sizeLarge {
			padding: 1.125rem 2.25rem;
			&.justIcon {
				padding: 1.125rem;
			}
		}

		&.MuiButton-sizeSmall {
			padding: 0.40625rem 1.25rem;
		}

		&:focus,
		&:hover {
			color: $gray-4;
			background: transparent;
			box-shadow: none;
			border: 1px solid $gray-4;
		}

		@each $name, $color in $normal-colors {
			&.#{$name} {
				color: $color;
				border-color: $color;

				&:focus,
				&:hover,
				&:visited {
					color: $color;
					border-color: $color;
				}
			}
		}
	}
}
