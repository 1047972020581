.requests-list {
	.multi-columns {
		justify-content: space-between;
	}

	.filters-container {
		margin-top: $padding-xs;
		margin-bottom: $padding-sm;

		.switchbar-container {
			display: flex;
			justify-content: flex-end;
			align-items: flex-end;

			.horizontal-switch {
				flex-grow: 1;
				max-width: 500px;

				.MuiButtonGroup-root {
					flex-grow: 1;

					.standart-button {
						flex: 1;
						padding-left: 18px;
						padding-right: 18px;
					}
				}

				@media (max-width: $lg-width) {
					max-width: unset;
				}
			}
		}

		.standart-button {
			margin-top: 0;
			margin-bottom: 0;
		}

		.MuiSwitchBarLabel {
			color: $gray-4;
		}
	}

	.table-placeholder {
		height: 60px;
		display: flex;
		flex-grow: 1;
		justify-content: center;
		align-items: center;
		background-color: $gray-1;
	}
	.requests-table {
		margin-top: $padding-sm;

		.MuiTableCell-head {
			.MuiTypography-subtitle1 {
				color: $black;
			}
		}

		.MuiTableHead-root {
			.MuiTableCell-root {
				height: 60px;
				padding: $padding-xxs;

				.head-cell-content {
					display: flex;
					justify-content: center;

					&.sorted {
						cursor: pointer;
					}

					.icon {
						align-self: center;
						margin-left: 10px;
						font-size: 12px;
						display: block;
						margin-bottom: 2px;
					}

					.asc-sort-icon {
						transform: rotate(180deg);
					}

					.desc-sort-icon {
						transform: rotate(0deg);
					}
				}
			}
		}

		.MuiTableCell-root {
			height: 70px;
			padding: 0;
			text-align: center;
			background-color: $white;

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: $padding-xxs;
				width: 100%;
				height: 100%;
				box-sizing: border-box;
			}

			.MuiButton-root {
				display: inline-flex;
				margin: 0 $padding-xxs;
				padding: $padding-xxs $padding-sm;
				width: 30px;
				height: 30px;

				.icon {
					font-size: 20px;
				}
			}
		}

		.edit-submit {
			width: 33%;
		}
		.MuiTableBody-root {
			.MuiTableRow-root {
				background-color: $white;

				&:hover {
					.MuiTableCell-root {
						background-color: $info-1;
					}
				}

				&:nth-child(odd) {
					.MuiTableCell-root {
						background-color: rgba($gray-1, 0.75);
					}
					&:hover {
						.MuiTableCell-root {
							background-color: $info-1;
						}
					}
				}

				.name-cell {
					position: relative;

					.appNameIcon {
						display: flex;
						justify-content: center;
						align-items: center;
						color: $gray-3;
						width: 62px;
						height: 62px;
						margin-right: 16px;
					}

					.campaign-type-icon-container {
						display: flex;
						position: absolute;
						align-self: flex-start;
						margin-left: 3px;
						margin-top: 3px;

						& > * {
							font-size: 22px;
							width: 100%;
							line-height: normal;
						}

						.backdrop {
							color: $white;
						}

						.fill {
							position: absolute;
							color: $new;
						}
					}

					img.app-icon {
						height: 62px;
						margin-right: 16px;
						border-radius: 2px;
					}

					.appName-container {
						padding-right: $padding-xxs;

						.appName {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							max-width: 180px;
						}

						.status {
							font-size: 20px;
							margin-right: 5px;
						}
					}

					.request-info-container {
						display: flex;
						justify-content: space-between;
						align-items: flex-end;

						.teamName-and-stepper {
							flex-grow: 1;
							min-width: 180px;
						}
					}

					.teamName-root {
						width: 100%;
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						.text-ellipsis {
							flex: 1;
							max-width: 230px;
							text-align: left;
							padding-right: $padding-xxs;
						}
					}

					.genre-chip {
						width: 120px;
						text-align: right;

						.MuiChip-root {
							background-color: $new;
							color: $white;
						}
					}

					.MuiStepper-root {
						padding: 0;
						margin-top: 4px;

						.MuiStep-root {
							padding: 0;
							width: 4px;
							height: 4px;
							border-radius: 50%;
							background-color: $white;
							z-index: 2;
						}

						.MuiStepConnector-root {
							margin-left: -6px;
							margin-right: -6px;

							.MuiStepConnector-line {
								border-color: $gray-2;
								border-top-width: 6px;
								border-radius: 6px;
							}

							&.MuiStepConnector-active,
							&.MuiStepConnector-completed {
								z-index: 1;

								.MuiStepConnector-line {
									border-color: $new;
								}
							}
						}
					}
				}

				.actions-cell {
					width: 36px;

					.icon {
						font-size: $h6-size;
						line-height: $h6-size;
						color: $success-0;
					}
				}
			}
		}
	}

	.table-pagination {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-top: $padding-xs;
		overflow-x: auto;

		.MuiTablePagination-root {
			flex-shrink: 0;

			.MuiTablePagination-toolbar {
				padding-left: unset;
			}
		}
	}
}
