.text {
	&-primary {
		color: $primary-0;
	}
	&-secondary {
		color: $secondary-0;
	}
	&-info {
		color: $info-0;
	}
	&-info-2 {
		color: $info-2;
	}
	&-success {
		color: $success-0;
	}
	&-warning {
		color: $warning-0;
	}
	&-danger {
		color: $danger-0;
	}
	&-rose {
		color: $rose-0;
	}
	&-white {
		color: $white;
	}
	&-twitter {
		color: $twitter;
	}
	&-telegram {
		color: $telegram;
	}
	&-facebook {
		color: $facebook;
	}
	&-google {
		color: $google;
	}
	&-linkedin {
		color: $linkedin;
	}
	&-pinterest {
		color: $pinterest;
	}
	&-youtube {
		color: $youtube;
	}
	&-tumblr {
		color: $tumblr;
	}
	&-github {
		color: $github;
	}
	&-behance {
		color: $behance;
	}
	&-dribbble {
		color: $dribbble;
	}
	&-reddit {
		color: $reddit;
	}
	&-instagram {
		color: $instagram;
	}
	&-apple {
		color: $apple;
	}
	&-new {
		color: $new;
	}
	&-validation {
		color: $validation;
	}
	&-running {
		color: $running;
	}
	&-transparent {
		color: inherit;
	}
	&-gray {
		color: $gray-2;
	}
}

.bg {
	&-primary {
		background-color: $primary-0;
	}
	&-secondary {
		background-color: $secondary-0;
	}
	&-info {
		background-color: $info-0;
	}
	&-success {
		background-color: $success-0;
	}
	&-warning {
		background-color: $warning-0;
	}
	&-danger {
		background-color: $danger-0;
	}
	&-rose {
		background-color: $rose-0;
	}
	&-white {
		background-color: $white;
	}
	&-twitter {
		background-color: $twitter;
	}
	&-telegram {
		background-color: $telegram;
	}
	&-facebook {
		background-color: $facebook;
	}
	&-google {
		background-color: $google;
	}
	&-linkedin {
		background-color: $linkedin;
	}
	&-pinterest {
		background-color: $pinterest;
	}
	&-youtube {
		background-color: $youtube;
	}
	&-tumblr {
		background-color: $tumblr;
	}
	&-github {
		background-color: $github;
	}
	&-behance {
		background-color: $behance;
	}
	&-dribbble {
		background-color: $dribbble;
	}
	&-reddit {
		background-color: $reddit;
	}
	&-instagram {
		background-color: $instagram;
	}
	&-apple {
		background-color: $apple;
	}
	&-new {
		background-color: $new;
	}
	&-validation {
		background-color: $validation;
	}
	&-running {
		background-color: $running;
	}
	&-transparent {
		background-color: inherit;
	}
	&-gray {
		background-color: $gray-2;
	}
}
