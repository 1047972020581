.upsert-developer-form {
	.inner-title {
		font-size: 22px;
		margin-top: $padding-sm;
		margin-bottom: $padding-xs;
	}

	.submit-button {
		margin-top: $padding-sm;
		margin-bottom: $padding-md;

		.progress {
			position: absolute;
			left: 30px;
			top: 10px;
		}
	}

	.info-message {
		font-weight: 300;
	}

	.action-grid-root {
		padding-top: 0;
		padding-bottom: 0;

		.action-root {
			max-width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-bottom: $padding-md;
			padding: 5px 15px;
			background-color: $white;
			border-radius: 8px;

			.info-root {
				display: flex;
				flex-direction: row;
				align-items: center;

				.MuiTypography-subtitle2 {
					font-size: $subtitle1-size;
				}

				.icon {
					margin-right: 15px;
					color: $danger-0;
					font-size: 22px;
				}

				.icon-request-list {
					color: $secondary-0;
				}
			}

			.standart-button {
				width: 145px;
			}

			@media (max-width: $sm-width) {
				display: block;

				.standart-button {
					width: 100%;
				}
			}
		}
	}

	.profile-link {
		max-width: 100%;
		margin: 0 0 $padding-md;
		padding: $padding-xs 15px $padding-sm;
		background-color: $white;
		border-radius: 8px;

		.MuiTypography-subtitle2 {
			.MuiTypography-subtitle1 {
				margin-left: $padding-xs;
			}
		}

		.info-root {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: $padding-xxs;

			.MuiTypography-subtitle2 {
				font-size: $subtitle1-size;
			}

			.icon {
				margin-right: 15px;
				font-size: 22px;

				&.icon-info1 {
					color: $secondary-0;
				}
			}
		}
	}

	.update-api-key {
		max-width: 100%;
		margin: $padding-md 0;
		padding: 15px 15px 5px 15px;
		background-color: $white;
		border-radius: 8px;

		.MuiTypography-subtitle2 {
			color: $gray-3;
		}

		.action-root {
			margin-bottom: 0;
			padding: 0;
			background-color: transparent;
		}
	}

	.required-fields-text {
		margin-top: $padding-md;
	}

	.MuiBox-root.MuiFormControl-marginNormal {
		.MuiTypography-body1 {
			word-break: break-word;
		}
	}
}
