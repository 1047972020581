.MuiStepper-root {
	padding: 0 0 $padding-md 0;

	.MuiStepLabel-root {
		display: flex;
		align-items: center;
	}
	.MuiStepLabel-root.MuiStepLabel-alternativeLabel {
		flex-direction: column;
	}
	.MuiStepLabel-root.Mui-disabled {
		cursor: default;
	}
	.MuiStepLabel-label {
		color: rgba(0, 0, 0, 0.54);
		font-size: $caption-size;
		line-height: $caption-line;
	}
	.MuiStepLabel-label.MuiStepLabel-active {
		color: rgba(0, 0, 0, 0.87);
		font-weight: 500;
	}
	.MuiStepLabel-label.MuiStepLabel-completed {
		color: rgba(0, 0, 0, 0.87);
		font-weight: 500;
	}
	.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
		margin-top: 16px;
		text-align: center;
	}
	.MuiStepLabel-label.Mui-error {
		color: $danger-0;
	}
	.MuiStepLabel-iconContainer {
		display: flex;
		flex-shrink: 0;
		padding-right: 8px;
	}
	.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
		padding-right: 0;
	}
	.MuiStepLabel-labelContainer {
		width: 100%;
	}

	.MuiStepIcon-root {
		color: $white;
		display: block;
		font-size: $subtitle1-size;
		line-height: $subtitle1-line;
		font-weight: 700;
		width: 26px;
		height: 26px;
		padding: 2px;
		border-radius: 15px;
		background-color: $gray-3;
		text-align: center;

		&.MuiStepIcon-completed {
			width: 16px;
			height: 16px;
			padding: 5px;
			border-radius: 15px;
			border: 2px solid $secondary-0;
			color: $primary-0;
			font-size: $body1-size;
			line-height: $body1-size;
			background-color: transparent;
		}
		&.MuiStepIcon-active {
			background-color: $secondary-0;
		}
		&.Mui-error {
			color: $danger-0;
		}
	}
	.MuiStepIcon-text {
		fill: $white;
		font-size: 0.75rem;
		font-family: $font;
	}
}
