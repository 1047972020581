.game-icon {
	overflow: hidden;
	min-width: fit-content;
	max-height: fit-content;
	position: relative;

	& > img {
		display: block;
		max-height: 100%;
	}

	& > .icon {
		position: absolute;
		top: 0;
		right: 0;

		&.icon-tt {
			font-size: $padding-sm;
			color: $white;
			background-color: $black;
			border-bottom-left-radius: 6px;
		}
	}
}
