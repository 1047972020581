.presets-dialog {
	.MuiDialog-paper {
		max-width: 726px;

		.MuiMenu-list {
			&.multi-columns {
				.MuiGrid-item {
					display: flex;
					align-items: center;
					justify-content: space-between;

					&:nth-child(odd) {
						padding-left: 0;
					}
					&:nth-child(even) {
						padding-right: $padding-xs;
					}

					.MuiListItem-root {
						width: 100%;

						.status {
							margin-right: 5px;
						}
					}

					.MuiSlider-root {
						.MuiSlider-rail {
							color: $gray-2;
						}
						.MuiSlider-track {
							color: $primary-0;
						}
						.MuiSlider-thumb {
							color: $primary-0;
							&:hover {
								box-shadow: 0px 0px 0px 8px rgba($primary-0, 0.16);
							}
							&.MuiSlider-active {
								box-shadow: 0px 0px 0px 14px rgba($primary-0, 0.16);
							}

							.MuiSlider-valueLabel {
								width: auto;
								min-width: 50px;
								transform: translateY(4px);
								display: flex;

								:only-child {
									width: auto;
									color: $gray-4;
									background-color: transparent;
									text-align: center;
								}
							}
						}
						.MuiSlider-mark {
							top: 10px;
							width: 4px;
							height: 4px;
							margin-left: -4px;
							border-radius: 50%;
							color: $white;
							border: 2px solid $gray-2;
							background-color: $white;
							&Active {
								border-color: rgba($primary-0, 0.8);
								opacity: 1;
							}
						}

						&.Mui-disabled {
							.MuiSlider-thumb {
								color: $gray-2;

								.MuiSlider-valueLabel {
									display: none;
								}
							}
						}
					}
				}
			}
		}

		.icon-info {
			font-size: 22px;
			color: $secondary-0;
			margin-right: $padding-xs;
		}
	}
}
