.request-create-stepper {
	.MuiStep-root {
		margin-top: $padding-md;
		.top-label {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			margin-top: -$padding-md;
			text-align: center;
			color: $gray-4;
		}

		.MuiStepLabel-label {
			color: $gray-3;
			font-size: $body2-size;
			line-height: $body2-line;
		}
	}
}
