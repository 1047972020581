.pro-dashboard {
	.title-container {
		margin-bottom: $padding-md;
	}

	.filters-container {
		margin-bottom: $padding-md;

		.date-pickers-container {
			display: flex;
			align-items: center;
			padding-bottom: $padding-sm;

			& > * {
				flex-shrink: 4;
			}

			.MuiTextField-root {
				margin-right: $padding-sm;
			}

			.MuiIconButton-root {
				color: $secondary-0;
			}

			.date-picker-with-button {
				display: flex;
				flex-shrink: 3;
			}

			@media (max-width: $sm-width) {
				flex-direction: column;
				align-items: stretch;

				.date-picker-with-button {
					margin-top: $padding-xs;
					.MuiTextField-root {
						flex-basis: 100%;
					}
				}
			}
		}

		.report-summary-container {
			display: flex;
			width: 100%;
			justify-content: space-between;
			padding-bottom: $padding-sm;
			order: -1;

			.report-summary-item {
				background-color: $white;
				padding: $padding-xxs $padding-xs;
				flex-shrink: 1;
				flex-basis: 250px;
				min-width: 0;
				margin-left: $padding-xs;
				border-radius: 3px;

				transition: opacity 0.2s, background-color 0.2s;
				&:first-of-type {
					margin-left: 0;
				}

				.label-container {
					color: $secondary-0;
					margin-bottom: $padding-xxs;
					.MuiTypography-root {
						color: inherit;
					}
				}

				.value-container {
					display: flex;
					justify-content: space-between;

					.unit {
						color: $gray-3;
					}
				}

				&.positive {
					.value-container {
						.value {
							color: $primary-0;
						}
					}

					&.colored {
						background-color: rgba($success-1, 0.4);
					}
				}

				&.negative {
					.value-container {
						.value {
							color: $warning-0;
						}
					}

					&.colored {
						background-color: rgba($warning-1, 0.4);

						.value {
							color: $rose-0;
						}
					}
				}

				&.disabled {
					opacity: 0.4;
				}
			}

			@media (min-width: $lg-width) {
				order: 0;

				.report-summary-item:first-of-type {
					margin-left: $padding-sm;
				}
			}

			@media (max-width: $sm-width) {
				flex-direction: column;
				.report-summary-item {
					flex-basis: unset;
					margin-left: 0;
					margin-top: $padding-xs;
					&:first-of-type {
						margin-left: 0;
					}
				}
			}
		}

		.search-and-switch-container {
			display: flex;

			.MuiFormControl-fullWidth {
				width: unset;
				flex-grow: 1;
				min-width: 350px;
				padding-right: $padding-sm;
			}

			.horizontal-switch {
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				white-space: nowrap;

				legend {
					@extend .MuiTypography-body1;
					margin-right: $padding-xs;
					margin-bottom: 0;
				}

				.MuiButtonGroup-root {
					button {
						padding: 12px 15px;
					}
				}
			}

			@media (max-width: $lg-width) {
				flex-direction: column;

				.horizontal-switch {
					white-space: normal;
				}

				.MuiFormControl-fullWidth {
					width: 100%;
					flex-grow: 0;
					margin-bottom: $padding-sm;
				}
			}

			@media (max-width: 440px) {
				.horizontal-switch {
					flex-direction: column;
					align-items: flex-start;
				}
			}
		}
	}

	.games-list {
		margin-bottom: $padding-md;
		position: relative;

		&.min-height {
			min-height: 40vh;
		}

		.placeholder-view {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: -1;
			background-color: $gray-0;
		}

		.MuiTable-root {
			background-color: $white;
			table-layout: fixed;
			min-width: 1180px;

			&[style*="visibility: hidden"] {
				display: none;
			}

			.MuiTableCell-root {
				height: 70px;
				padding: 0 5px;
				text-align: center;
				background-color: $white;
			}

			.MuiTableHead-root {
				.MuiTableCell-root {
					height: 60px;
					padding: $padding-xxs;

					.head-cell-content {
						display: flex;
						justify-content: center;
					}

					.icon {
						color: $secondary-0;
						font-size: 11px;
						display: block;
						margin-bottom: 2px;
					}
				}

				.DeveloperName {
					width: 200px;
				}

				.Spend,
				.TotalSpend,
				.Value,
				.AdRevenue,
				.Income {
					width: 100px;
				}

				.Roas,
				.RetentionD1,
				.RevenueSharing {
					width: 80px;
				}

				.EditProject {
					width: 60px;
				}
			}

			.MuiTableBody-root {
				.MuiTableRow-root {
					&:nth-child(odd) {
						.MuiTableCell-root {
							background-color: rgba($gray-1, 0.75);
						}
					}
					&.interactive:hover {
						cursor: pointer;
						.MuiTableCell-root {
							background-color: $info-1;
						}
					}

					.MuiTableCell-root {
						padding: 0;
						box-sizing: border-box;
						a {
							display: flex;
							flex-direction: column;
							justify-content: center;
							padding: 0 5px;
							color: $gray-4;
							height: 100%;
						}
					}
				}

				.name-cell {
					text-align: left;

					.name-cell-content {
						display: flex;
						align-items: center;

						.game-icon {
							margin-right: 16px;
							height: 62px;
							border-radius: 2px;
						}

						.text-ellipsis {
							.icon {
								color: $secondary-1;
								margin-right: $padding-xxs;
							}
						}

						.MuiChip-root {
							margin-left: $padding-xs;
						}
					}
				}

				.edit-project-cell {
					i.icon {
						font-size: 24px;
						color: $success-0;
					}
				}
			}
		}
	}
}
