.time-range-selector {
	font-size: 17px;
	line-height: 22px;

	.selector-container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-top: 16px;

		.time {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			flex: 1;

			&:first-child {
				margin-right: 16px;
			}

			.label {
				padding-right: 16px;
			}
		}
	}
}
