.MuiCircularProgress-colorPrimary {
	color: $primary-0;
}
.MuiCircularProgress-colorSecondary {
	color: $secondary-0;
}

.MuiLinearProgress-root {
	height: 4px;
}
.MuiLinearProgress-colorPrimary {
	background-color: $primary-1;
}
.MuiLinearProgress-colorSecondary {
	background-color: $secondary-1;
}

.MuiLinearProgress-dashedColorPrimary {
	background-size: 10px 10px;
	background-image: radial-gradient($primary-1 0%, $primary-1 16%, transparent 42%);
	background-position: 0 -23px;
}
.MuiLinearProgress-dashedColorSecondary {
	background-size: 10px 10px;
	background-image: radial-gradient($secondary-1 0%, $secondary-1 16%, transparent 42%);
	background-position: 0 -23px;
}
.MuiLinearProgress-bar {
	top: 0;
	left: 0;
	width: 100%;
	bottom: 0;
	position: absolute;
	transition: transform 0.2s linear;
	transform-origin: left;
}
.MuiLinearProgress-barColorPrimary {
	background-color: $primary-0;
}
.MuiLinearProgress-barColorSecondary {
	background-color: $secondary-0;
}
