//Media width
$xs-width: 0;
$sm-width: 576px;
$md-width: 768px;
$lg-width: 992px;
$xl-width: 1100px;

$padding-xxs: 5px;
$padding-xs: 10px;
$padding-sm: 20px;
$padding-md: 40px;
$padding-lg: 60px;
$padding-xl: 80px;

//Text
$font: "Open Sans", "Helvetica", "Arial", sans-serif;
$font-title: "Istok Web", "Helvetica", "Arial", sans-serif;
$h1-size: 53px;
$h1-line: 62px;
$h2-size: 53px;
$h2-line: 62px;
$h3-size: 36px;
$h3-line: 52px;
$h4-size: 36px;
$h4-line: 45px;
$h5-size: 25px;
$h5-line: 34px;
$h6-size: 25px;
$h6-line: 34px;
$subtitle1-size: 18px;
$subtitle1-line: 26px;
$subtitle2-size: 18px;
$subtitle2-line: 25px;
$body1-size: 16px;
$body1-line: 22px;
$body2-size: 14px;
$body2-line: 19px;
$caption-size: 12px;
$caption-line: 16px;

// Palete

$default: #878685;
$black: #000;
$gray-4: #3f3b47;
$gray-3: #9f9f9f;
$gray-2: #cecece;
$gray-1: #ebebeb;
$gray-0: #f3f6f5;
$graybook: #fafafa;
$white: #fff;

$error-main: #e13a46;

$primary-0: #09814a;
$primary-1: #15ad67;
$primary-2: #08673b;

$secondary-0: #5f3887;
$secondary-1: #8468a2;
$secondary-2: #a897bd;

$info-0: #b8e1f3;
$info-1: #c7e7f3;
$info-2: #6ea9ad;

$warning-0: #d39f36;
$warning-1: #f4bb48;
$warning-2: #cd8c09;

$danger-0: #f44336;
$danger-1: #ff6559;
$danger-2: #d72619;

$success-0: #42ad42;
$success-1: #6ec06e;
$success-2: #0d662d;

$rose-0: #e91e63;
$rose-1: #ff538d;
$rose-2: #d01555;

//Social colors
$twitter: #55acee;
$telegram: #0299c6;
$facebook: #3b5998;
$google: #dd4b39;
$linkedin: #0976b4;
$pinterest: #cc2127;
$youtube: #e52d27;
$tumblr: #35465c;
$behance: #1769ff;
$dribbble: #ea4c89;
$reddit: #ff4500;
$instagram: #125688;
$github: #333;
$apple: #111;

//Additional status colors
$validation: #5e5ce6;
$new: #00afff;
$running: #aa78b7;

$normal-colors: (
	"black": $black,
	"white": $white,
	"error-main": $error-main,
	"primary": $primary-0,
	"secondary": $secondary-0,
	"info": $info-0,
	"warning": $warning-0,
	"danger": $danger-0,
	"success": $success-0,
	"rose": $rose-0,
	"twitter": $twitter,
	"telegram": $telegram,
	"facebook": $facebook,
	"google": $google,
	"linkedin": $linkedin,
	"pinterest": $pinterest,
	"youtube": $youtube,
	"tumblr": $tumblr,
	"behance": $behance,
	"dribbble": $dribbble,
	"reddit": $reddit,
	"instagram": $instagram,
	"github": $github,
	"apple": $apple,
	"validation": $validation,
	"new": $new,
	"running": $running,
);

$lighter-colors: (
	"black": $black,
	"white": $white,
	"error-main": $error-main,
	"primary": $primary-1,
	"secondary": $secondary-0,
	"info": $info-2,
	"warning": $warning-2,
	"danger": $danger-2,
	"success": $success-2,
	"rose": $rose-2,
	"twitter": $twitter,
	"telegram": $telegram,
	"facebook": $facebook,
	"google": $google,
	"linkedin": $linkedin,
	"pinterest": $pinterest,
	"youtube": $youtube,
	"tumblr": $tumblr,
	"behance": $behance,
	"dribbble": $dribbble,
	"reddit": $reddit,
	"instagram": $instagram,
	"github": $github,
	"apple": $apple,
	"validation": $validation,
	"new": $new,
	"running": $running,
);

$darker-colors: (
	"black": $black,
	"white": $white,
	"error-main": $error-main,
	"primary": $primary-2,
	"secondary": $secondary-1,
	"info": $info-1,
	"warning": $warning-1,
	"danger": $danger-1,
	"success": $success-1,
	"rose": $rose-1,
	"twitter": $twitter,
	"telegram": $telegram,
	"facebook": $facebook,
	"google": $google,
	"linkedin": $linkedin,
	"pinterest": $pinterest,
	"youtube": $youtube,
	"tumblr": $tumblr,
	"behance": $behance,
	"dribbble": $dribbble,
	"reddit": $reddit,
	"instagram": $instagram,
	"github": $github,
	"apple": $apple,
	"validation": $validation,
	"new": $new,
	"running": $running,
);
