.boxShadow {
	box-shadow: 0 10px 30px -12px rgba($black, 0.42), 0 4px 25px 0px rgba($black, 0.12),
		0 8px 10px -5px rgba($black, 0.2);
}
.primaryBoxShadow {
	box-shadow: 0 12px 20px -10px rgba($primary-0, 0.28), 0 4px 20px 0px rgba($black, 0.12),
		0 7px 8px -5px rgba($primary-0, 0.2);
}
.infoBoxShadow {
	box-shadow: 0 12px 20px -10px rgba($info-0, 0.28), 0 4px 20px 0px rgba($black, 0.12),
		0 7px 8px -5px rgba($info-0, 0.2);
}
.successBoxShadow {
	box-shadow: 0 12px 20px -10px rgba($success-0, 0.28), 0 4px 20px 0px rgba($black, 0.12),
		0 7px 8px -5px rgba($success-0, 0.2);
}
.warningBoxShadow {
	box-shadow: 0 12px 20px -10px rgba($warning-0, 0.28), 0 4px 20px 0px rgba($black, 0.12),
		0 7px 8px -5px rgba($warning-0, 0.2);
}
.dangerBoxShadow {
	box-shadow: 0 12px 20px -10px rgba($danger-0, 0.28), 0 4px 20px 0px rgba($black, 0.12),
		0 7px 8px -5px rgba($danger-0, 0.2);
}
.roseBoxShadow {
	box-shadow: 0 4px 20px 0px rgba($black, 0.14), 0 7px 10px -5px rgba($rose-0, 0.4);
}
