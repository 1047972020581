.MuiCheckbox-root {
	color: rgba(0, 0, 0, 0.54);
}
.MuiCheckbox-colorPrimary.Mui-checked {
	color: $primary-0;
}
.MuiCheckbox-colorPrimary.Mui-disabled {
	color: rgba(0, 0, 0, 0.26);
}
.MuiCheckbox-colorPrimary.Mui-checked:hover {
	background-color: rgba($primary-0, 0.04);
}
@media (hover: none) {
	.MuiCheckbox-colorPrimary.Mui-checked:hover {
		background-color: transparent;
	}
}
.MuiCheckbox-colorSecondary.Mui-checked {
	color: $secondary-0;
}
.MuiCheckbox-colorSecondary.Mui-disabled {
	color: rgba(0, 0, 0, 0.26);
}
.MuiCheckbox-colorSecondary.Mui-checked:hover {
	background-color: rgba($secondary-0, 0.04);
}
@media (hover: none) {
	.MuiCheckbox-colorSecondary.Mui-checked:hover {
		background-color: transparent;
	}
}
