.publisher-login-view {
	form {
		margin-top: -$padding-md;

		@media (min-width: $md-width) {
			margin-left: -$padding-md;
			margin-right: -$padding-md;
		}

		.MuiButton-root {
			margin-top: $padding-md;
		}
	}
}
