.user-menu {
	.MuiDrawer-paper {
		width: 300px;

		.menu-header {
			display: flex;
			flex-direction: row;
			align-items: center;
			background-color: $primary-2;
			color: $white;
			height: 80px;

			.name {
				flex-grow: 1;
				width: 250px;

				h1 {
					font-size: 22px;
					margin: 22px 0 22px 15px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
			}

			.MuiIconButton-root {
				height: 48px;
				width: 48px;
				color: $white;
			}
		}

		.MuiListItem-button {
			padding: 0 15px;

			.MuiListItemText-primary {
				font-size: 20px;
				line-height: 25px;
				letter-spacing: 0.38px;
			}

			.MuiListItemText-secondary {
				font-size: 15px;
				line-height: 18px;
				color: $gray-3;
			}
			&:hover {
				.MuiListItemText-primary {
					color: $primary-0;
				}
				.MuiListItemIcon-root {
					color: $primary-0;
				}
			}
			.MuiListItemIcon-root {
				min-width: 37px;
				font-size: 22px;
				color: $gray-3;
			}
		}
	}
}
