.help-view {
	.breadcrumbs-container {
		background-color: $white;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		box-sizing: border-box;
		padding: $padding-sm 120px;
		@media (max-width: $md-width) {
			padding-left: $padding-md;
			padding-right: $padding-md;
		}

		nav {
			ol > li {
				color: $gray-3;
				.MuiTypography-caption {
					color: inherit;
					display: flex;
					align-items: center;
				}
				a {
					color: inherit;
					&:hover {
						color: $gray-4;
					}
				}
				&:last-child {
					color: $secondary-0;
				}

				&.MuiBreadcrumbs-separator {
					font-size: 12px;
					line-height: $caption-line;
					margin-left: $padding-xxs;
					margin-right: $padding-xxs;
				}

				i {
					color: $secondary-0;
					font-size: 20px;
					margin-right: $padding-xs;
				}
			}
		}
	}

	.title-container {
		width: 100%;
		display: flex;
		align-items: center;
		margin: $padding-md 0;
		padding-left: $padding-md;

		.go-back-button {
			margin-left: -$padding-md;
		}
	}

	.contents-list {
		color: $secondary-0;
		list-style: none;

		& > li {
			margin-bottom: $padding-xs;
			&::before {
				content: "\25CF";
				font-size: $h5-size;
				margin-right: $padding-xs;
			}
			& > a {
				font-weight: 700;
				color: inherit;

				&:hover {
					text-decoration: underline;
				}
			}

			.topics-list {
				padding-left: $h5-size;
			}
		}
	}

	.topics-list {
		margin-top: $padding-xs;
		list-style: none;
		padding-left: $padding-md;
		color: $gray-3;

		a {
			color: inherit;
			&:hover {
				text-decoration: underline;
				color: $gray-4;
			}
		}
	}

	.help-topic {
		margin-top: $padding-sm;
		padding-left: $padding-md;
		padding-right: $padding-md;

		.url-text {
			justify-content: flex-start;
			align-items: flex-start;

			i {
				font-size: 20px;
			}
		}

		p {
			margin-bottom: $padding-sm;

			& > br + .link {
				display: block;
				margin-left: unset;
				margin-top: $padding-xs;
			}
		}

		li {
			margin-bottom: $padding-xs;

			.MuiBox-root {
				display: inline-block;
			}

			a {
				margin: 0 0 0 $padding-xs;
			}

			.info-box {
				display: flex;
				margin: 5px 0;

				.text-container {
					display: flex;

					ul {
						margin: 10px 0 5px 0;
					}
				}
			}
		}

		ol {
			padding-left: 0;
			li {
				list-style-position: inside;
			}

			p {
				margin-bottom: 0;
			}
		}

		ul {
			margin-top: $padding-sm;
			margin-bottom: $padding-sm;

			& > li {
				list-style-position: outside;
				list-style-type: disc;

				&::marker {
					color: $secondary-0;
				}
			}

			p {
				margin-bottom: 0;
			}
		}

		button {
			color: $secondary-0;
		}

		.code {
			display: block;
			font-family: "Consolas", Courier, monospace;
			background-color: $gray-4;
			color: $gray-1;
			padding: 1ch;
			tab-size: 4;
		}

		.text-with-button {
			display: flex;
			p {
				margin-bottom: 0;
			}
		}

		.link {
			margin-left: $padding-xs;
			color: $secondary-0;

			i {
				font-size: 20px;
				margin-right: $padding-xxs;
				vertical-align: text-top;
			}

			&:hover {
				color: $secondary-2;
			}
		}

		img {
			max-width: 100%;
		}

		.img-wrap {
			align-self: center;
			margin-top: $padding-sm;
		}

		.genre-container {
			.standart-accordion-hint {
				margin-bottom: 0;
			}
			.MuiAccordionDetails-root {
				flex-direction: column;
				.previews {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					margin: -8px;

					.preview-container {
						margin: 8px;
						position: relative;
						background-color: $gray-1;
						width: 86px;
						height: 86px;
						border-radius: 4px;

						.MuiAvatar-root {
							width: 86px;
							height: 86px;
							border-radius: 4px;
						}

						.MuiIconButton-root {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 40px;
							height: 40px;
							background-color: $gray-2;
							border-radius: 50%;
							opacity: 0.8;

							.icon {
								color: $white;
								font-size: 40px;
								line-height: 40px;
								width: 40px;
								height: 40px;
							}
						}

						.MuiIcon-root {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 45px;
							height: 45px;

							.icon {
								color: $secondary-0;
								font-size: 45px;
								line-height: 45px;
								width: 45px;
								height: 45px;
							}
						}
					}
				}
			}
		}

		.doc-view {
			.link {
				margin: 0;
			}

			ol {
				.MuiTypography-body1 {
					margin-bottom: $padding-sm;

					&:first-of-type {
						margin-top: $padding-sm;
					}
				}
			}
		}
	}
}
