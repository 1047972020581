.infoArea {
	margin: 0 auto;
	padding-bottom: $padding-md;

	&.vertical {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.iconWrapper {
		float: left;
		margin-right: 10px;

		.MuiIcon-root {
			width: 2.25rem;
			height: 2.25rem;
			font-size: 2.25rem;

			&.vertical {
				font-size: 4rem;
				.MuiSvgIcon-root {
					width: 4rem;
					height: 4rem;
				}
			}
		}

		&.vertical {
			float: none;
		}
	}

	.descriptionWrapper {
		overflow: hidden;

		.title {
			color: $secondary-0;
			margin: 0 0 0.875rem;
			min-height: unset;
		}
		.description {
			overflow: hidden;
			margin-top: "0px";
		}
	}
}
