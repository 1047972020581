.game-info-dialog.withCloseButton {
	.MuiDialogTitle-root {
		padding-left: 0;

		.MuiTypography-root {
			display: flex;
			align-items: center;

			i.icon {
				color: $gray-3;
				margin-right: $padding-xxs;

				&.icon-apple {
					margin-bottom: $padding-xxs;
				}
			}
		}
	}

	.game-info-dialog-container {
		.standart-button {
			box-sizing: border-box;
			min-width: unset;
			width: 100%;
			margin: 0;
			padding: $padding-xs $padding-md;
		}
		.game-info-container {
			align-items: center;
			.logo-container {
				height: fit-content;
				margin-right: $padding-sm;

				img {
					display: block;
					border-radius: 6px;
				}
			}

			.rating-and-bundle-container {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 100%;

				.rating {
					display: flex;
					align-items: center;
				}
			}
		}

		.game-links-container {
			a {
				display: block;
				width: 100%;
				margin-top: $padding-xs;
			}
		}

		.MuiTypography-subtitle1 {
			margin-top: $padding-sm;
		}

		.settings-container {
			.MuiGrid-item:not(:last-child) {
				margin-right: $padding-xs;
			}

			.save-button-container {
				display: flex;
			}
		}
	}
}

.MuiAutocomplete-popper {
	.MuiAutocomplete-listbox {
		max-height: 30vh;
	}
}
