.main {
	background: $gray-0;
	position: relative;
	z-index: 3;

	&.margin {
		margin-top: 120px;
	}
}

.mainRaised {
	margin: 0 30px;
	border-radius: 6px;
	box-shadow: 0 16px 24px 2px rgba($black, 0.14), 0 6px 30px 5px rgba($black, 0.12), 0 8px 10px -5px rgba($black, 0.2);

	@media (max-width: $sm-width) {
		margin-top: -30px;
	}

	@media (max-width: 830px) {
		margin-left: 10px;
		margin-right: 10px;
	}
}

.grow {
	flex-grow: 1;
}

.coloredShadow {
	// some jss/css to make the cards look a bit better on Internet Explorer
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		display: none !important;
	}
	transform: scale(0.94);
	top: 12px;
	filter: blur(12px);
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: cover;
	z-index: -1;
	transition: opacity 0.45s;
	opacity: 0;
}

.title {
	color: $gray-4;
	text-decoration: none;
	font-weight: 700;
	margin-top: 30px;
	margin-bottom: 25px;
	min-height: 32px;
}

.full-width {
	width: 100%;
}

.cardTitle {
	@extend .title;
	margin-top: 0.625rem;
	margin-bottom: 0.75rem;
	min-height: auto;
	a {
		@extend .title;
		margin-top: 0.625rem;
		margin-bottom: 0.75rem;
		min-height: auto;
	}
}

a {
	text-decoration: none;
	&.url-text {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		color: $secondary-1;
		overflow: hidden;
		white-space: nowrap;

		&:hover {
			color: $secondary-2;
		}
		.MuiTypography-root {
			color: $secondary-1;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-right: $padding-xs;
			white-space: nowrap;

			&:hover {
				color: $secondary-2;
			}
			.icon {
				margin-right: $padding-xs;
				margin-left: $padding-xxs;
			}
		}
	}
}

.hidden {
	display: none !important;
}
