.standart-accordion {
	border: none;
	margin-top: $padding-md;
	&.Mui-expanded {
		margin: $padding-md auto auto;
	}
	&::before {
		display: none;
	}
	&:hover {
		.MuiAccordionSummary-expandIcon {
			background-color: $gray-1;
		}
	}

	.MuiAccordionSummary-root {
		padding-left: 0;
		padding-right: 0;
		min-height: unset;

		.standart-accordion-hint {
			transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			opacity: 1;
		}

		&.Mui-expanded {
			min-height: unset;

			.standart-accordion-hint {
				opacity: 0;
			}
		}

		.MuiAccordionSummary-content {
			margin: 0;
			flex-direction: column;
			&.Mui-expanded {
				margin: auto;
			}

			.MuiDivider-root {
				margin-bottom: $padding-xxs;
			}
		}

		.MuiAccordionSummary-expandIcon {
			box-sizing: border-box;
			margin-top: $padding-xxs;
			width: $padding-md;
			height: $padding-md;
			transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
				transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

			i {
				font-size: 16px;
			}
		}
	}

	.MuiAccordionDetails-root {
		padding: $padding-sm 0 0 0;
	}
}
