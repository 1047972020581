.MuiDialog-root {
	.MuiDialog-paper {
		max-width: 686px;
		padding-top: 8px;
		border-radius: 12px;

		.MuiDialogContent-root {
			display: flex;
			flex-direction: column;
			padding-bottom: 24px;

			&.confirm-content {
				align-items: center;

				.MuiTypography-root {
					text-align: center;
				}

				.button-container {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					flex-wrap: wrap;

					.dialog-button {
						margin: $padding-sm $padding-xs 1px $padding-xs;
					}
				}
			}

			.MuiButton-root {
				margin-top: $padding-sm;
				min-width: 160px;
				align-self: center;
			}
		}
	}

	&.withCloseButton {
		.MuiDialogTitle-root {
			margin: 0 $padding-sm;
		}
		.closeButton {
			position: absolute;
			top: 16px;
			right: 16px;
			width: 24px;
			height: 24px;
			font-size: 24px;
			color: $gray-3;
		}
	}
}
