.card {
	border: 0;
	margin-bottom: $padding-md;
	border-radius: 6px;
	color: rgba($black, 0.87);
	background: $white;
	width: 100%;
	box-shadow: 0 2px 2px 0 rgba($black, 0.14), 0 3px 1px -2px rgba($black, 0.2), 0 1px 5px 0 rgba($black, 0.12);
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	font-size: 0.875rem;
	// some jss/css to make the cards look a bit better on Internet Explorer
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		display: inline-block !important;
	}
	.MuiGrid-container {
		.MuiGrid-item {
			&:first-child {
				@media (min-width: $lg-width) {
					margin-right: $padding-md;
				}
			}
		}
	}

	&.plain {
		background: transparent;
		box-shadow: none;
	}

	&.profile {
		text-align: center;
	}

	&.blog {
		margin-top: $padding-lg;
	}

	&.raised {
		box-shadow: 0 16px 38px -12px rgba($black, 0.56), 0 4px 25px 0px rgba($black, 0.12),
			0 8px 10px -5px rgba($black, 0.2);
	}
	&.background {
		background-position: 50%;
		background-size: cover;
		text-align: center;
		color: $white;
		.h3 {
			color: $white !important;
		}
		.p {
			color: rgba($white, 0.7) !important;
		}
		&:after {
			position: absolute;
			z-index: 1;
			width: 100%;
			height: 100%;
			display: block;
			left: 0;
			top: 0;
			content: "";
			background-color: rgba($black, 0.56);
			border-radius: 6px;
		}
		small {
			color: rgba($white, 0.7) !important;
		}
	}
	&.pricing {
		text-align: center;
		&:after {
			background-color: rgba($black, 0.7) !important;
		}
		ul {
			list-style: none;
			padding: 0;
			max-width: 240px;
			margin: 10px auto;

			&li {
				color: $gray-3;
				text-align: center;
				padding: 12px 0px;
				border-bottom: 1px solid rgba($gray-3, 0.3);

				&:last-child {
					border: 0;
				}

				&b {
					color: $gray-4;
				}
			}
		}
		h1 {
			margin-top: 30px;
			.small {
				display: inline-flex;
				height: 0;
				font-size: 18px;

				&:first-child {
					position: relative;
					top: -17px;
					font-size: 26px;
				}
			}
		}
		ul {
			&li {
				&svg,
				&.fab,
				&.fas,
				&.far,
				&.fal,
				&.material-icons {
					position: relative;
					top: 7px;
				}
			}
		}
	}
	&.pricingColor {
		ul {
			&li {
				color: $white;
				border-color: rgba($white, 0.3);
				&b,
				&svg,
				&.fab,
				&.fas,
				&.far,
				&.fal,
				&.material-icons {
					color: $white;
					font-weight: 700;
				}
			}
		}
	}
	&.product {
		margin-top: 30px;
	}

	&.primary {
		background: linear-gradient(60deg, $primary-1, $primary-2);
		h1 {
			&small {
				color: rgba($white, 0.8);
			}
		}
		color: $white;
	}
	&.info {
		background: linear-gradient(60deg, $info-1, $info-2);
		h1 {
			&small {
				color: rgba($white, 0.8);
			}
		}
		color: $white;
	}
	&.success {
		background: linear-gradient(60deg, $success-1, $success-2);
		h1 {
			&small {
				color: rgba($white, 0.8);
			}
		}
		color: $white;
	}
	&.warning {
		background: linear-gradient(60deg, $warning-1, $warning-2);
		h1 {
			&small {
				color: rgba($white, 0.8);
			}
		}
		color: $white;
	}
	&.danger {
		background: linear-gradient(60deg, $danger-1, $danger-2);
		h1 {
			&small {
				color: rgba($white, 0.8);
			}
		}
		color: $white;
	}
	&.rose {
		background: linear-gradient(60deg, $rose-1, $rose-2);
		h1 {
			&small {
				color: rgba($white, 0.8);
			}
		}
		color: $white;
	}

	.cardHeader {
		border-radius: 3px;
		padding: 1rem 15px;
		border: 0;
		margin: -30px 15px 0 15px;

		&.plain {
			margin-left: 0px;
			margin-right: 0px;
			&.image {
				margin: 0;
			}
		}
		&.image {
			position: relative;
			padding: 0;
			z-index: 1;
			margin: -30px 15px 0 15px;
			border-radius: 6px;
			img {
				width: 100%;
				border-radius: 6px;
				pointer-events: none;
				box-shadow: 0 5px 15px -8px rgba($black, 0.24), 0 8px 10px -5px rgba($black, 0.2);
			}
			a {
				display: block;
			}
		}
		.noShadow {
			.img {
				box-shadow: none !important;
			}
		}
		&.contact {
			margin: 0 15px -20px 15px;
		}
		&.signup {
			margin: -40px 20px 15px 20px;
			padding: 20px 0;
			width: 100%;
		}

		&.warning {
			color: whiteColor;
			background: linear-gradient(60deg, $warning-1, $warning-2);
			@extend .warningBoxShadow;
		}
		&.success {
			color: whiteColor;
			background: linear-gradient(60deg, $success-1, $success-2);
			@extend .successBoxShadow;
		}
		&.danger {
			color: whiteColor;
			background: linear-gradient(60deg, $danger-1, $danger-2);
			@extend .dangerBoxShadow;
		}
		&.info {
			color: whiteColor;
			background: linear-gradient(60deg, $info-1, $info-2);
			@extend .infoBoxShadow;
		}
		&.primary {
			color: whiteColor;
			background: linear-gradient(60deg, $primary-1, $primary-2);
			@extend .primaryBoxShadow;
		}
		&.rose {
			color: whiteColor;
			background: linear-gradient(60deg, $rose-1, $rose-2);
			@extend .roseBoxShadow;
		}
	}

	.cardBody {
		flex: 1 1 auto;
		-webkit-box-flex: 1;

		&.background {
			position: relative;
			z-index: 2;
			min-height: 280px;
			padding-top: 40px;
			padding-bottom: 40px;
			max-width: 440px;
			margin: 0 auto;
		}
		&.plain {
			padding-left: 5px;
			padding-right: 5px;
		}
		&.formHorizontal {
			padding-left: 15px;
			padding-right: 15px;
			.form {
				margin: 0;
			}
		}
		&.pricing {
			padding: 15px !important;
			margin: 0px !important;
		}
		&.signup {
			padding: 0px 30px 0px 30px;
		}
		&.color {
			border-radius: 6px;
			h1,
			h2,
			h3 {
				color: rgba($white, 0.8);
			}
		}

		h5 {
			margin-bottom: $padding-sm;
		}

		.description {
			@media (max-width: $lg-width) {
				margin-top: $padding-md;
			}
		}
	}

	.cardFooter {
		display: flex;
		align-items: center;
		background-color: transparent;
		padding: 0.9375rem 1.875rem;
		padding-top: 0;

		&.profile {
			margin-top: -15px;
		}

		&.plain {
			padding-left: 5px;
			padding-right: 5px;
			background-color: transparent;
		}

		&.pricing {
			z-index: 2;
		}

		&.testimonial {
			display: block;
		}
	}
}
