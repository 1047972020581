.appBar {
	display: flex;
	border: 0;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	margin-bottom: 20px;
	color: $gray-4;
	width: 100%;
	background-color: $white;
	box-shadow: 0 4px 18px 0px rgba($black, 0.12), 0 7px 10px -5px rgba($black, 0.15);
	transition: all 150ms ease 0s;
	transition: padding-right 0ms;
	align-items: center;
	flex-flow: row nowrap;
	justify-content: center;
	position: relative;

	&.absolute {
		position: absolute;
		top: auto;
	}
	&.fixed {
		position: fixed;
	}
	.links-container {
		min-height: 50px;
		align-items: center;
		justify-content: space-between;
		display: flex;
		flex-wrap: nowrap;
		flex-grow: 1;
		padding-left: $padding-sm;
		padding-right: $padding-sm;

		@media (min-width: $sm-width) {
			max-width: 620px;
		}
		@media (min-width: $md-width) {
			max-width: 880px;
		}
		@media (min-width: $lg-width) {
			max-width: 1100px;
			padding-right: unset;
			padding-left: unset;
		}
	}

	.title {
		letter-spacing: unset;
		min-width: unset;
		line-height: 30px;
		font-size: 18px;
		border-radius: 3px;
		text-transform: none;
		white-space: nowrap;
		color: $white;

		.MuiButton-endIcon {
			padding-left: 16px;
		}
	}

	a {
		&:hover,
		&:focus,
		&:visited {
			color: inherit;
		}
	}

	.MuiButton-root {
		min-width: 48px;

		&.MuiButton-contained {
			background-color: $white;
		}

		&.MuiButton-outlined {
			border-color: $white;
			color: $white;
		}
	}

	.appResponsive {
		margin: 20px 10px;
		margin-top: 0px;
	}

	.button-container {
		display: flex;
		flex-direction: row;
		align-items: center;

		.MuiButtonGroup-root {
			margin-left: 16px;
		}
		.MuiAvatar-root {
			margin: 0 16px;
			overflow: visible;

			.MuiTouchRipple-root {
				$padding: $padding-xxs;
				top: -$padding;
				right: -$padding;
				bottom: -$padding;
				left: -$padding;
			}
		}

		.contacts-button {
			.MuiButton-label {
				font-size: 24px;
			}
		}
	}

	.loading-progress {
		position: absolute;
		bottom: 0;
		width: 100%;
		background-color: transparent;

		.MuiLinearProgress-bar {
			background-color: $secondary-2;
		}
	}

	&.primary {
		background-color: $primary-0;
		color: $white;
		box-shadow: 0 4px 20px 0px rgba($black, 0.14), 0 7px 12px -5px rgba($primary-0, 0.46);
	}
	&.secondary {
		background-color: $secondary-0;
		color: $white;
		box-shadow: 0 4px 20px 0px rgba($black, 0.14), 0 7px 12px -5px rgba($secondary-0, 0.46);
	}
	&.info {
		background-color: $info-0;
		color: $white;
		box-shadow: 0 4px 20px 0px rgba($black, 0.14), 0 7px 12px -5px rgba($info-0, 0.46);
	}
	&.success {
		background-color: $success-0;
		color: $white;
		box-shadow: 0 4px 20px 0px rgba($black, 0.14), 0 7px 12px -5px rgba($success-0, 0.46);
	}
	&.warning {
		background-color: $warning-0;
		color: $white;
		box-shadow: 0 4px 20px 0px rgba($black, 0.14), 0 7px 12px -5px rgba($warning-0, 0.46);
	}
	&.danger {
		background-color: $danger-0;
		color: $white;
		box-shadow: 0 4px 20px 0px rgba($black, 0.14), 0 7px 12px -5px rgba($danger-0, 0.46);
	}
	&.rose {
		background-color: $rose-0;
		color: $white;
		box-shadow: 0 4px 20px 0px rgba($black, 0.14), 0 7px 12px -5px rgba($rose-0, 0.46);
	}
	&.transparent {
		background-color: transparent;
		box-shadow: none;
		color: $white;

		.loading-progress {
			top: 0;
			bottom: auto;
		}
	}
	&.dark {
		color: $white;
		background-color: $gray-4;
		box-shadow: 0 4px 20px 0px rgba($black, 0.14), 0 7px 12px -5px rgba($gray-4, 0.46);
	}
	&.white {
		border: 0;
		padding: 0.625rem 0;
		margin-bottom: 20px;
		color: $gray-4;
		background-color: $white;
		box-shadow: 0 4px 18px 0px rgba($black, 0.12), 0 7px 10px -5px rgba($black, 0.15);
	}

	.collapse {
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;

		@media (min-width: $md-width) {
			display: flex;
			-ms-flex-preferred-size: auto;
			flex-basis: auto;
		}
	}
}
