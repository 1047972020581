.edit-expense-dialog {
	.MuiDialogTitle-root {
		.MuiTypography-root {
			text-align: center;
		}
	}
	form#edit-expense-form {
		& > * {
			margin-top: $padding-sm;

			&:first-child {
				padding-right: $padding-md;

				@media (max-width: $sm-width) {
					padding-right: unset;
				}
			}
		}
	}

	.MuiDialogActions-root {
		justify-content: center;

		.standart-button {
			flex-basis: 40%;
		}
	}
}
