.game-landing {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.bg {
		position: fixed;
		height: 100%;
		width: 100%;
		background-image: url("images/game-landing-bg.jpg");
		background-position: center;
		background-size: cover;
		z-index: -1;

		&:before {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: linear-gradient(90deg, #f3f6f5 0%, rgba(243, 246, 245, 0.67) 51.56%, #f3f6f5 100%);
			content: "";
		}
	}

	main {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-grow: 1;

		.game-card {
			position: relative;
			display: flex;
			flex-direction: column;
			background-color: $white;
			border-radius: $padding-sm;
			padding: $padding-sm;
			max-width: Min(820px, calc(100vw - 40px));
			margin: $padding-sm;
			box-sizing: border-box;

			.content {
				display: flex;
				flex-direction: row;

				.game-icon {
					margin-right: $padding-sm;

					img {
						border-radius: $padding-xs;
					}
				}

				.text {
					overflow: hidden;

					.MuiTypography-root {
						color: $secondary-0;
					}

					h3 {
						font-weight: 700;
						font-size: $h4-size;
						line-height: $h3-line;
						margin-bottom: $padding-sm;
					}

					q {
						overflow-wrap: break-word;
					}
				}

				@media (max-width: $sm-width) {
					flex-direction: column;

					.game-icon {
						text-align: center;
						margin-right: unset;
					}

					.text {
						margin-top: $padding-sm;
					}
				}
			}

			.actions {
				display: flex;
				justify-content: flex-end;

				& > *:nth-child(2) {
					margin-left: $padding-sm;
				}

				.MuiButton-root {
					margin-top: $padding-sm;
					padding: 10px 30px;
					border-radius: $padding-xs;
					background-color: $secondary-0;
					flex-shrink: 0;

					.MuiButton-label {
						font-size: $h5-size;
						text-transform: none;
						height: $padding-md;

						.icon {
							font-size: $padding-md;

							&.icon-apple {
								margin-bottom: $padding-xxs;
							}

							&.icon-google-play {
								font-size: 24px;
							}
						}
					}
				}

				@media (max-width: $sm-width) {
					flex-wrap: wrap;

					.MuiButton-root {
						padding: 5px 15px;
						width: 200px;

						.MuiButton-label {
							font-size: 20px;
							height: $padding-md;

							.icon {
								font-size: 30px;

								&.icon-google-play {
									font-size: 20px;
								}
							}
						}
					}
				}
			}

			.rings {
				position: absolute;
				left: 0;
				bottom: 0;
				transform: translate(-50%, 50%);
				content: "";
				z-index: -1;
			}
		}
	}

	footer {
		display: flex;
		justify-content: center;
		background-color: $secondary-0;
		color: $white;

		.copyrights {
			margin-block: $padding-sm;
		}
	}
}
