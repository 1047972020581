.espresso-datepicker {
	border: none !important;

	.react-datepicker__header {
		border: none;
		background-color: $white;
	}

	.react-datepicker__day {
		font-size: 17px;
		font-weight: 400;
		line-height: 35px;
		letter-spacing: -0.41px;
		text-align: center;
		width: 35px;
		height: 35px;
		border-radius: 50%;

		&--today {
			border: 1px solid $gray-2;
		}

		&--selected,
		&--keyboard-selected {
			background-color: $primary-0;

			&:hover {
				background-color: $primary-2 !important;
			}
		}

		&--disabled {
			color: $gray-2;
		}

		&--weekend {
			color: $error-main;
		}

		&:hover {
			border-radius: 50%;
			background-color: $gray-2;
		}
	}
	.react-datepicker__day-name {
		font-size: 17px;
		font-weight: 600;
		line-height: 22px;
		width: 35px;
		height: 22px;
		color: $black;
		text-transform: uppercase;
	}

	.react-datepicker__navigation {
		&--previous {
			border-right-color: $primary-0;
		}
		&--next {
			border-left-color: $primary-0;
		}
	}
}
