.request-actions {
	.MuiDialog-paper {
		background-color: $gray-0;
		max-width: 778px;

		.status-selector {
			display: flex;
			align-items: center;
			align-self: center;

			@media (max-width: $md-width) {
				flex-direction: column;
				width: 100%;
			}

			.MuiButton-root {
				height: 104px;
				margin: $padding-sm $padding-xs;

				&.MuiButton-text {
					background-color: $white;
				}

				@media (max-width: $md-width) {
					margin: $padding-xs 0;
					width: 100%;

					.MuiButton-label {
						font-weight: 700;
						font-size: $body2-size;
						line-height: $body2-line;

						.MuiBox-root {
							flex-direction: row;
							align-items: center;
							width: 100%;
							max-width: 200px;

							span {
								flex-grow: 1;
							}
						}
					}
				}

				&:first-child {
					margin-left: 0;

					@media (max-width: $md-width) {
						margin-top: $padding-sm;
						margin-bottom: 0;
					}
				}
				&:last-child {
					margin-right: 0;

					@media (max-width: $md-width) {
						margin-bottom: $padding-sm;
					}
				}

				.icon {
					font-size: 30px;
					margin-bottom: $padding-sm;

					@media (max-width: $md-width) {
						font-size: 40px;
						margin-bottom: 0;
						margin-right: $padding-sm;
					}
				}
			}
			.divider {
				width: 30px;
				height: 30px;
			}
		}

		.MuiInput-root {
			margin-bottom: $padding-xs;
		}

		.status-description {
			display: flex;
			align-items: center;
			color: $secondary-0;
			margin-bottom: $padding-sm;

			.icon-info {
				font-size: 22px;
				margin-right: $padding-xs;
			}
		}

		.lang-warning {
			display: flex;
			align-items: center;
			color: $danger-0;

			.icon-attention {
				font-size: 22px;
				margin-right: $padding-xs;
			}
			.MuiTypography-root {
				color: $danger-0;
			}
		}
	}
}
