.info-graphic-slider {
	--swiper-pagination-bullet-horizontal-gap: #{$padding-xs};
	--swiper-pagination-bullet-size: min(2.5vw, 14px);
	--swiper-pagination-color: #{$secondary-0};
	--swiper-pagination-bullet-inactive-color: #{$gray-2};
	--swiper-pagination-bullet-inactive-opacity: 1;

	max-width: 100%;
	padding-bottom: $padding-md;
	margin-bottom: $padding-xs;

	.swiper-wrapper {
		.slide-step {
			width: 100%;
			height: auto;
		}
	}
}
