.home-view {
	.title-container {
		display: flex;
		align-items: center;
	}

	&.developer-requests {
		.title-container {
			align-items: baseline;
		}

		.developer-name {
			margin-left: $padding-xs;
			font-weight: 400;
			font-size: $h5-size;
			line-height: $h5-line;
			color: $secondary-0;
		}
	}

	.buttons-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		.deleted-list-button {
			margin-left: 10px;
		}
	}

	.presets-container {
		display: flex;
		flex-grow: 1;
		align-items: center;
		justify-content: space-between;
		margin-top: $padding-sm;

		.items-container {
			.MuiButton-root {
				padding: 10px 8px;
				margin: 0 5px;

				.badge {
					width: 21px;
					height: 21px;
					border-radius: 50%;

					display: flex;
					align-items: center;
					justify-content: center;
					color: $white;
					margin-left: $padding-xxs;
					&.active {
						background-color: rgba($white, 0.5);
						color: rgba($gray-4, 0.7);
					}
				}
			}
		}

		.justIcon {
			color: $black;
			&.Mui-disabled {
				color: $gray-3;
			}
		}
	}
}
