.buttons-group-formControl {
	&.horizontal-switch {
		@media (min-width: $lg-width) {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.MuiSwitchBarLabel {
				color: $gray-3;
				margin-bottom: 0;
				margin-right: 10px;
			}
		}
	}

	.MuiSwitchBarLabel {
		color: $gray-3;
		margin-bottom: 12px;
	}

	.MuiButton-startIcon {
		margin: 0;
	}
}

.MuiButtonGroup-root {
	border-radius: 500px;

	.MuiButton-root {
		min-width: 0;
		margin-right: 0;
		&:first-child {
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
		}

		&:last-child {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}
	}

	&.round {
		.MuiButton-root {
			&:first-child {
				border-top-left-radius: 500px;
				border-bottom-left-radius: 500px;
			}

			&:last-child {
				border-top-right-radius: 500px;
				border-bottom-right-radius: 500px;
			}
		}
	}
}
.MuiButtonGroup-grouped {
	border-radius: 500px;
	min-width: 64px;
}
.MuiButtonGroup-groupedTextPrimary:not(:last-child) {
	border-color: $primary-0;
}
.MuiButtonGroup-groupedTextSecondary:not(:last-child) {
	border-color: $secondary-0;
}
.MuiButtonGroup-groupedOutlinedPrimary:hover {
	border-color: $primary-0;
}
.MuiButtonGroup-groupedOutlinedSecondary:hover {
	border-color: $secondary-0;
}
.MuiButtonGroup-groupedContainedHorizontal:not(:last-child).Mui-disabled {
	border-right: 1px solid $gray-2;
}
.MuiButtonGroup-groupedContainedVertical:not(:last-child).Mui-disabled {
	border-bottom: 1px solid $gray-2;
}
.MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
	border-color: $primary-0;
}
.MuiButtonGroup-groupedContainedSecondary:not(:last-child) {
	border-color: $secondary-0;
}
