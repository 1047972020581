.drawerPaper {
	border: none;
	bottom: 0;
	transition-property: top, bottom, width;
	transition-duration: 0.2s, 0.2s, 0.35s;
	transition-timing-function: linear, linear, ease;
	width: 260px;
	position: fixed;
	display: block;
	top: 0;
	height: 100vh;
	right: 0;
	left: auto;
	visibility: visible;
	overflow-y: visible;
	border-top: none;
	text-align: left;
	padding-right: 0px;
	padding-left: 0;
	transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);

	.button-container {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 10px;
		margin-left: 20px;
		.MuiButton-root {
			&.MuiButton-contained {
				background-color: $secondary-0 !important;
				.MuiButton-label {
					color: $white;
				}
			}
			box-shadow: none;
			.MuiButton-label {
				color: $secondary-0;
			}
		}
		.MuiButtonGroup-root {
			margin-top: 13px;
			.MuiButtonGroup-groupedOutlinedPrimary {
				border-color: $secondary-0 !important;
			}
			.MuiButton-containedPrimary {
				background-color: $secondary-0 !important;

				.MuiButton-label {
					color: $white;
				}
			}
		}
	}
	.closeButtonDrawer {
		position: absolute;
		right: 8px;
		top: 9px;
		z-index: 1;
	}
}
