.MuiAutocomplete-popper {
	.MuiPaper-root {
		margin: 0;
		box-shadow: 0px 2px 5px 0px #00000080;
	}
	.MuiAutocomplete-listbox,
	.MuiAutocomplete-option {
		padding: 0;
	}

	.MuiAutocomplete-option[data-focus="true"] {
		background-color: $gray-0;
		color: $success-0;

		.MuiListItemText-secondary {
			color: $success-1;
		}
	}
}
