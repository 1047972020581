.MuiTypography-root {
	white-space: pre-wrap;
}
.MuiTypography-h1 {
	font-size: $h1-size;
	font-family: $font-title;
	font-weight: 700;
	line-height: $h1-line;
}
.MuiTypography-h2 {
	font-size: $h2-size;
	font-family: $font;
	font-weight: 400;
	line-height: $h2-line;
}
.MuiTypography-h3 {
	font-size: $h3-size;
	font-family: $font-title;
	font-weight: 700;
	line-height: $h3-line;
}
.MuiTypography-h4 {
	font-size: $h4-size;
	font-family: $font;
	font-weight: 400;
	line-height: $h4-line;
}
.MuiTypography-h5 {
	font-size: $h5-size;
	font-family: $font-title;
	font-weight: 700;
	line-height: $h5-line;
}
.MuiTypography-h6 {
	font-size: $h6-size;
	font-family: $font;
	font-weight: 400;
	line-height: $h6-line;
}
.MuiTypography-subtitle1 {
	color: $black;
	font-size: $subtitle1-size;
	font-family: $font-title;
	font-weight: 700;
	line-height: $subtitle1-line;
}
.MuiTypography-subtitle2 {
	font-size: $subtitle2-size;
	font-family: $font;
	font-weight: 400;
	line-height: $subtitle2-line;
}
.MuiTypography-body1 {
	color: $gray-4;
	font-size: $body1-size;
	font-family: $font;
	font-weight: 400;
	line-height: $body1-line;
}
.MuiTypography-body2 {
	color: $gray-3;
	font-size: $body2-size;
	font-family: $font;
	font-weight: 400;
	line-height: $body2-line;
}
.MuiTypography-caption {
	color: $gray-2;
	font-size: $caption-size;
	font-family: $font;
	font-weight: 400;
	line-height: $caption-line;
}
.MuiTypography-button {
	font-size: $body2-size;
	font-family: $font;
	font-weight: normal;
	line-height: $body2-line;
	text-transform: none;
}
.MuiTypography-overline {
	font-size: 0.75rem;
	font-family: $font;
	font-weight: 400;
	line-height: 2.66;
	text-transform: uppercase;
}
.MuiTypography-gutterBottom {
	margin-bottom: 0.35em;
}
.MuiTypography-paragraph {
	margin-bottom: 16px;
}
.MuiTypography-colorPrimary {
	color: $primary-0;
}
.MuiTypography-colorSecondary {
	color: $secondary-0;
}
.MuiTypography-colorTextPrimary {
	color: $gray-4;
}
.MuiTypography-colorTextSecondary {
	color: $gray-3;
}
.MuiTypography-colorError {
	color: $error-main;
}
