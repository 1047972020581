.info-dialog {
	.MuiDialog-paper {
		max-width: $lg-width;

		.MuiDialogTitle-root {
			text-align: center;
			margin-top: $padding-md;

			&.no-padding {
				padding: 0;
			}

			.MuiTypography-body2 {
				color: $gray-4;
			}
		}

		.MuiDialogContent-root {
			padding-left: $padding-lg;
			padding-right: $padding-lg;

			.MuiTypography-body1 {
				margin-bottom: $padding-xs;

				& > br + .link {
					display: block;
					margin-left: unset;
					margin-top: $padding-xs;
				}
			}

			.MuiIconButton-root {
				font-size: 24px;
				color: $secondary-0;
				padding: $padding-xs;
				height: fit-content;
			}

			.img-wrap {
				align-self: center;
				margin-top: $padding-sm;
				margin-bottom: $padding-xs;
			}

			img {
				max-width: 100%;
			}

			.url-text {
				justify-content: flex-start;

				.MuiTypography-body1 {
					color: $secondary-0;
					margin-bottom: 0;
				}
			}

			li {
				margin-bottom: $padding-xs;

				.MuiBox-root {
					display: inline-block;
				}

				.info-box {
					display: flex;
					margin: 5px 0;

					.text-container {
						display: flex;

						ul {
							margin: 10px 0 5px 0;

							& > li::marker {
								font-size: unset;
							}
						}
					}

					.MuiIconButton-sizeSmall {
						padding: 3px;
						font-size: 1.125rem;
					}
				}
			}

			ul > li {
				list-style-type: disc;

				&::marker {
					color: $secondary-1;
					font-size: 150%;
				}
			}

			.code {
				font-family: "Consolas", Courier, monospace;
				background-color: $gray-4;
				color: $gray-1;
				padding: 1ch;
				tab-size: 4;
			}

			.standart-button {
				margin: 0 0 0 -16px;
			}

			.text-with-button {
				display: flex;
			}

			h6 {
				margin: $padding-sm 0;
			}

			.link {
				margin-left: $padding-xs;
				color: $secondary-0;

				i {
					font-size: 20px;
					margin-right: $padding-xxs;
					vertical-align: text-top;
				}

				&:hover {
					color: $secondary-2;
				}
			}
		}
	}
}
