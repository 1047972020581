.copy-request {
	.MuiDialog-paper {
		max-width: $md-width;
		.MuiDialogContent-root {
			display: flex;
			flex-direction: column;
			padding: 0 $padding-xl $padding-md;

			.standart-button {
				margin: 0;
			}

			.test-type-selector {
				display: flex;
				align-self: center;
				justify-content: space-between;
				width: 100%;

				@media (max-width: $md-width) {
					flex-direction: column;
				}

				.standart-button {
					margin: 0 $padding-xs;
					height: 180px;
					width: 180px;
					border-radius: 8px;

					&:first-child {
						margin-left: 0;
					}
					&:last-child {
						margin-right: 0;
					}

					&.white {
						color: $secondary-0;
					}

					.MuiButton-label {
						font-weight: 700;
						font-size: $subtitle1-size;
						line-height: $subtitle1-line;
						.icon {
							font-size: 86px;
						}

						@media (max-width: $md-width) {
							.MuiBox-root {
								flex-direction: row;
								justify-content: space-between;
								align-items: center;

								.icon {
									margin-right: $padding-sm;
								}
							}
						}
					}

					@media (max-width: $md-width) {
						width: 100%;
						margin: $padding-xs 0;

						&:first-child {
							margin-top: 0;
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}

			.test-type-description {
				display: flex;
				align-items: center;
				color: $secondary-0;
				margin-top: $padding-sm;
				margin-bottom: $padding-sm;

				.icon-info {
					align-self: flex-start;
					font-size: 22px;
					margin-right: $padding-xs;
				}
			}

			@media (max-width: $sm-width) {
				padding: 0 $padding-sm $padding-sm;
			}
		}
	}
}
