.not-found-view {
	.image-root {
		display: flex;
		justify-content: center;
		margin-top: $padding-md;

		img {
			width: 600px;
			max-width: 100%;
		}
	}

	.info-root {
		margin-top: $padding-md;

		.MuiTypography-body1 {
			color: $gray-3;
			text-align: center;
			font-size: 25px;
			line-height: 34px;
		}
	}

	.buttons-root {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: $padding-md;

		a {
			width: 200px;
			margin-left: $padding-md;
			margin-right: $padding-md;

			.standart-button {
				width: 200px;
			}
		}

		@media (max-width: $md-width) {
			flex-direction: column;

			a {
				width: 100%;
				margin-left: 0;
				margin-right: 0;

				.standart-button {
					width: 100%;
				}
			}
		}
	}
}
