@each $name, $color in $normal-colors {
	$shadow-color: $color;
	@if $name == "white" {
		$shadow-color: $gray-4;
	}

	@keyframes Pulse-#{$name} {
		0% {
			box-shadow: 0 2px 2px 0 rgba($shadow-color, 0.14), 0 3px 1px -2px rgba($shadow-color, 0.2),
				0 1px 5px 0 rgba($shadow-color, 0.12);
		}
		50% {
			box-shadow: 0 14px 26px -12px rgba($shadow-color, 0.84), 0 4px 23px 0px rgba($black, 0.24),
				0 8px 10px -5px rgba($shadow-color, 0.4);
		}
		100% {
			box-shadow: 0 2px 2px 0 rgba($shadow-color, 0.14), 0 3px 1px -2px rgba($shadow-color, 0.2),
				0 1px 5px 0 rgba($shadow-color, 0.12);
		}
	}
}

body {
	background-color: $gray-1;
	font-family: $font;
	font-weight: 300;
	line-height: 1.5em;

	.app-container {
		display: flex;
		flex: 1;
		min-height: 100vh;
		justify-content: space-between;
		flex-direction: column;
		// max-width: 950px;
		margin: auto;

		.MuiBackdrop-root {
			z-index: 10;
		}

		a {
			outline: 0;
		}

		&.image-background {
			color: $white;
			border: 0;
			height: 100%;
			margin: 0;
			display: flex;
			position: relative;
			min-height: 100vh;
			align-items: center;
			background-size: cover;
			background-position: top center;

			&:before {
				background: rgba($black, 0.5);
			}
			&:before,
			&:after {
				position: absolute;
				z-index: 1;
				width: 100%;
				height: 100%;
				display: block;
				left: 0;
				top: 0;
				content: "";
			}
		}

		.MuiSnackbarContent-root {
			flex-wrap: nowrap;
		}

		.status-container {
			.icon {
				font-size: 20px;
				color: $secondary-0;

				&.not-checked {
					color: $gray-3;
				}
				&.checking {
					color: $warning-0;
				}
				&.valid {
					color: $success-0;
				}
				&.not-valid {
					color: $rose-0;
				}
			}
		}

		.select-input {
			.MuiSelect-select.MuiSelect-select {
				padding-right: 48px;
			}
			.clear-button {
				margin: 0 24px 0 -48px;
			}
		}

		.text-ellipsis {
			width: 100%;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.developer-requests-accordion {
			margin-bottom: $padding-md;
			max-width: 100%;
			width: 100%;

			&.Mui-expanded {
				margin-bottom: $padding-md;
			}

			.MuiAccordionDetails-root {
				display: block;

				.developer-info {
					display: flex;
					flex-direction: column;
					flex-wrap: wrap;
					justify-content: space-between;
					max-width: 100%;
					overflow: hidden;

					.name-container {
						display: flex;
						flex-wrap: wrap;
						max-width: 100%;
						align-items: baseline;
						margin-bottom: $padding-sm;

						& > :first-child {
							margin-right: $padding-xs;
						}

						.developer-name {
							max-width: 100%;
							width: unset;
						}
					}

					.actions-container {
						margin: unset;
						width: 100%;
						flex-grow: unset;
						justify-content: unset;
						flex-grow: 1;
						flex-direction: row;
						align-self: flex-start;
						margin-bottom: $padding-sm;

						a {
							width: 100%;
						}

						.standart-button {
							margin: 0;
							width: 100%;
						}
					}

					@media (min-width: $sm-width) {
						flex-direction: row;
						flex-wrap: nowrap;

						.name-container {
							flex-wrap: nowrap;
							min-width: 0;

							.developer-name {
								margin-right: $padding-sm;
								flex: 1;
							}
						}

						.actions-container {
							width: unset;
							flex-grow: 0;
						}
					}
				}

				.requests-table {
					margin-top: unset;
				}
			}
		}
	}

	.page-header {
		padding: 15px 25px;

		.page-title {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			cursor: pointer;

			img {
				width: 28px;
				height: 28px;
				background-color: $primary-0;
				border-radius: 50%;
				padding: 12px;
				margin-right: 25px;
			}
		}
		.MuiAvatar-root {
			margin-left: 20px;
		}
	}

	.page-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		padding: $padding-lg $padding-xl $padding-md $padding-xl;
		box-shadow: 2px 4px 20px rgba(0, 0, 0, 0.25);

		@media (max-width: $md-width) {
			padding: $padding-lg $padding-md $padding-md $padding-md;
		}

		.page-content-title {
			margin-bottom: $padding-md;
		}

		.button-width-medium {
			width: 200px;
		}
	}

	.error-text {
		color: $error-main;
	}

	.error-snackbar {
		.MuiSnackbarContent-root {
			background-color: $error-main;
			font-weight: bold;
			color: $white;
		}
	}

	.right {
		float: right;
	}

	.MuiTooltip-popper {
		.MuiTooltip-tooltip {
			background-color: $default;
			padding: $padding-xs;
			border-radius: 4px;
			border: 1px solid $gray-2;
			color: $white;
			font-size: $caption-size;
			line-height: $caption-line;

			ul {
				padding: 0 17px 0 17px;
				margin: $padding-xxs 0 0 0;
			}

			p {
				padding: 0;
				margin: 0;
			}

			.MuiTooltip-arrow {
				color: $info-1;
			}

			.tooltip-center {
				text-align: center;
			}
		}
	}

	.icon,
	.MuiCheckbox-root {
		&.requestStatus_FinishedBackgroundColor {
			color: $success-0;
		}
		&.requestStatus_NewBackgroundColor {
			color: $new;
		}
		&.requestStatus_NotValidBackgroundColor {
			color: $gray-3;
		}
		&.requestStatus_RejectedBackgroundColor {
			color: $warning-0;
		}
		&.requestStatus_RunFailedBackgroundColor {
			color: $rose-0;
		}
		&.requestStatus_RunningBackgroundColor {
			color: $secondary-2;
		}
		&.requestStatus_CampaignFinishedBackgroundColor {
			color: $secondary-0;
		}
		&.requestStatus_ValidBackgroundColor {
			color: $gray-3;
		}
		&.requestStatus_ValidationBackgroundColor {
			color: $validation;
		}
		&.requestStatus_InitialBackgroundColor {
			color: $gray-2;
		}
		&.requestStatus_PreValidationBackgroundColor {
			color: $gray-4;
		}
		&.requestStatus_InfoFacebookBackgroundColor {
			color: $info-2;
		}
		&.requestStatus_SetSDKBackgroundColor {
			color: $info-0;
		}
		&.requestStatus_ReturnedBackgroundColor {
			color: $warning-1;
		}
	}

	.status-filter {
		.status {
			margin-right: 6px;
			font-size: 20px;
		}
	}

	.metric-text {
		min-width: 64px;
		color: $gray-3;

		&.positive {
			color: $success-0;
		}

		&.negative {
			color: $rose-0;
		}
		&.intermediate {
			color: $warning-0;
		}
	}

	.developer-contacts-title {
		margin-top: 40px;
	}

	.MuiGrid-container {
		&.multi-columns {
			.MuiGrid-item {
				padding: 0 $padding-sm;

				&.item-no-indent {
					padding: 0;
				}

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}

				@media (max-width: $md-width) {
					padding: 0;
				}

				&:not(:first-child) {
					@media (max-width: $md-width) {
						margin-top: $padding-md;
					}
				}
			}
		}
	}

	.required-fields-text {
		&.MuiTypography-body1 {
			margin-bottom: $padding-sm;
			color: $gray-4;

			.required-info {
				font-size: 13px;
			}
		}
	}
}
