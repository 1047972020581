@import "./mui-autocomplete-popper.scss";

.app-container,
.MuiDialog-root,
.MuiDrawer-paper,
.MuiPopover-root {
	@import "./parallax.scss";
	@import "./header.scss";
	@import "./standart-button.scss";
	@import "./footer.scss";
	@import "./info-area.scss";
	@import "./social-auth.scss";
	@import "./standart-info-block.scss";
	@import "./request-create-stepper.scss";
	@import "./placeholder-view.scss";
	@import "./lang-picker.scss";
	@import "./info-box.scss";
	@import "./option-card.scss";
	@import "./go-back-button.scss";
	@import "./calendar-popover-root.scss";
	@import "./standart-accordion.scss";
	@import "./game-icon.scss";
	@import "./contacts-popover-root.scss";
	@import "./genre-list.scss";
	@import "./info-graphic-slider.scss";
}
