.request-view {
	.delete-button {
		position: absolute;
		top: 5px;
		right: 5px;
	}

	.shadowBox {
		background: $white;
		box-shadow: 0px 1.48178px 1.48178px rgba($black, 0.14), 0px 2.22267px 0.740891px -1.48178px rgba($black, 0.2),
			0px 0.740891px 3.70445px rgba($black, 0.12);
		border-radius: 4.44534px;
		padding: 0 $padding-xs;
		min-width: 160px;
		min-height: 80px;
		margin-bottom: $padding-md;
		display: flex;
		align-items: center;
		margin-right: 40px;
	}

	.requestid-content-root {
		display: flex;
		align-items: center;

		.request-deleted-text {
			color: $danger-0;
		}
	}

	.header {
		margin-bottom: $padding-sm;
		margin-top: $padding-xs;

		.info-root {
			width: 100%;
			display: flex;
			align-items: center;
		}
	}

	.link {
		.MuiTypography-body1 {
			margin-top: 5px;
		}
	}

	a {
		.MuiTypography-body1 {
			height: $body1-line;

			.icon {
				margin-right: $padding-xs;
				color: $gray-3;
			}
		}
	}

	.multi-columns {
		.MuiGrid-item {
			&:not(:first-child) {
				margin-top: 0;
			}
		}
	}

	.status-text {
		padding-bottom: 10px;
	}

	.MuiFormControl-marginNormal:first-child {
		margin-top: 0;
	}

	.status-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;

		&.FacebookIdStatus {
			@media (max-width: $md-width) {
				margin-top: $padding-sm;
			}
		}

		.content {
			flex-grow: 1;

			.status-text {
				margin-top: $padding-sm;
			}
		}
		.status-container {
			display: flex;
			justify-content: center;
			width: $padding-md;
			margin-bottom: $padding-xs;
			margin-left: $padding-xs;

			.icon {
				font-size: 20px;
				width: 20px;
				height: 20px;
				border-radius: 16px;
				display: block;
			}
		}

		.MuiIconButton-root {
			margin-bottom: -12px;
			padding: 10px;
			color: $secondary-1;
		}

		.reset-campaign {
			margin: 0;
			padding: 0;
		}

		.MuiFormControl-fullWidth {
			width: unset;
			flex-grow: 1;
		}

		.url-text {
			.icon-link {
				margin-right: $padding-xs;
			}
		}
	}

	.create-root {
		display: flex;
		flex-direction: row;
		align-items: center;

		.adaccount-usage-root {
			display: flex;
			margin-left: $padding-xs;

			.icon {
				font-size: 40px;
				color: $secondary-0;

				&.not-valid {
					color: $rose-0;
				}
				&.secondary {
					color: $warning-0;
				}
				&.valid {
					color: $success-0;
				}
			}
		}
	}

	.countdown-container {
		display: flex;
	}

	.video-gallery {
		margin-top: $padding-sm;
	}

	.result-block {
		margin-top: $padding-md;

		.result-table {
			margin-top: $padding-sm;

			.MuiTable-root {
				background-color: $white;

				.MuiTableCell-head {
					background-color: $white;
					@extend .MuiTypography-subtitle1;
					color: $gray-4;
				}

				.MuiTableRow-root {
					&.mainRow {
						&:nth-child(4n + 1) {
							background-color: $gray-1;
						}
						.MuiTableCell-root {
							@extend .MuiTypography-h5;
							color: $gray-4;
							line-height: 35px; //Хак из-за кривого шрифта istok web
						}
					}
					&.detailsRow {
						&:nth-child(4n + 2) {
							background-color: $gray-1;
						}
					}
					&.additionalRow {
						&:nth-child(4n + 3) {
							background-color: $gray-1;
						}
					}

					&.positive {
						.cell-value {
							color: $success-0;
						}
					}

					&.negative {
						.cell-value {
							color: $danger-0;
						}
					}

					&.intermediate {
						.cell-value {
							color: $warning-0;
						}
					}

					&.warning {
						.cell-value {
							color: $gray-3;

							div {
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;

								.icon {
									color: $warning-0;
									margin-right: $padding-xs;
								}
							}
						}
					}

					.cell-icon {
						div {
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}

					&:not(.description) {
						.MuiTableCell-root {
							border-bottom-width: 0;
						}
					}

					&.moreInfo-row {
						background-color: $gray-0;

						.statusInfo-label {
							color: $secondary-0;
							.icon {
								margin-right: $padding-xs;
							}
						}
					}

					&.description {
						background-color: $gray-0;
						border: none;
						.MuiTableCell-root {
							color: $gray-3;
							padding-left: 10%;
							padding-right: 10%;
							text-align: left;

							.metrics-expand-block {
								display: flex;
								flex-direction: column;
								.info-block {
									display: flex;
									align-items: center;
									justify-content: space-between;
									margin-bottom: $padding-sm;

									.MuiTypography-root {
										max-width: max-content;
									}

									.switch-bar-container {
										margin-left: $padding-xs;
									}
								}
								.table-block {
									.empty-list {
										display: flex;
										align-items: center;
										justify-content: center;
										height: 51px;
										color: $secondary-0;

										i {
											font-size: 22px;
											margin-right: $padding-xs;
										}
									}

									.metrics-expand-row {
										display: flex;
										align-items: center;
										justify-content: space-between;
										background-color: $white;
										padding: 5px 15px;

										&:hover {
											background-color: $gray-1;
										}

										&.mainRow {
											background-color: $gray-1;
										}

										.appNameIcon {
											display: flex;
											justify-content: center;
											align-items: center;
											color: $gray-3;
											width: 40px;
											height: 40px;
										}

										img.app-icon {
											height: 40px;
											border-radius: 2px;
										}

										.status {
											margin-left: 15px;
											margin-right: 10px;
										}

										.appName {
											flex-grow: 1;
											max-width: 250px;
											color: $black;

											white-space: nowrap;
											overflow: hidden;
											text-overflow: ellipsis;
										}

										.date {
											padding-left: $padding-sm;
											color: $black;
										}

										.metric-container-text {
											width: 122px;
											padding: 0 $padding-sm;
											display: flex;
											color: $gray-3;
										}

										.button-placeholder {
											width: 41px;
											height: 41px;
										}

										.MuiButton-root {
											padding: 0;
											margin: 0;
										}
									}
								}
							}
						}

						&.hidden {
							.MuiTableCell-root {
								transition: padding 0.45s;
								padding-top: 0;
								padding-bottom: 0;
							}
						}
					}

					.MuiTableCell-root {
						&.cell-fill {
							width: 4%;
						}
						&.cell-title {
							width: 24%;
						}
						&.cell-value {
							width: 24%;
						}
						&.cell-info {
							width: 24%;
						}
						&.cell-icon {
							width: 24%;
						}
					}
				}

				.MuiTableCell-root {
					@extend .MuiTypography-h6;
					color: $black;
					text-align: center;
				}

				.icon-info-outline {
					color: $secondary-0;
				}
			}
		}
	}
	.metrics-info {
		.icon {
			font-size: 19px;
			width: 19px;
			height: 19px;
			border-radius: 16px;
			display: inline-block;
			color: $secondary-0;
			margin-left: $padding-xs;

			&.valid {
				color: $success-0;
			}
			&.not-valid {
				color: $rose-0;
			}
			&.checking {
				color: $warning-0;
			}
		}
	}

	.MuiCircularProgress-root {
		margin-left: $padding-xs;
	}

	.actions-container {
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
		margin-top: $padding-sm;

		.standart-button {
			&.justIcon {
				margin: 0 0 0 $padding-sm;
			}

			&:only-child {
				margin-left: auto;
			}
		}

		@media (max-width: $md-width) {
			flex-direction: column-reverse;

			.standart-button {
				width: 100%;
				margin-top: $padding-sm;

				&.justIcon {
					margin-top: $padding-sm;
					margin-left: 0;
				}
			}
		}
	}

	.video-metrics-accordion {
		.video-metrics-table {
			background-color: $white;
			table-layout: fixed;
			min-width: 720px;

			.ctr,
			.ipm,
			.cr {
				width: 60px;
			}

			.impression {
				width: 120px;
			}

			.install,
			.click {
				width: 100px;
			}

			.MuiTableBody-root {
				.MuiTableRow-root {
					&:nth-child(odd) {
						background-color: $gray-1;
					}
					&:hover {
						background-color: $info-1;
					}
				}
			}

			.MuiTableCell-root {
				text-align: center;
				color: $gray-4;
				border: 0;
			}

			.MuiTableCell-head {
				@extend .MuiTypography-subtitle1;
				padding: 16px $padding-xs;

				.icon {
					color: $secondary-0;
					font-size: 11px;
					display: block;
					margin-bottom: 2px;
				}
			}

			.MuiTableCell-body {
				@extend .MuiTypography-subtitle2;
				padding: $padding-xxs;

				&.positive {
					font-weight: 700;
					color: $success-0;
				}

				&.negative {
					font-weight: 700;
					color: $rose-0;
				}

				&.intermediate {
					font-weight: 700;
					color: $warning-0;
				}

				.video-cell-content {
					display: flex;
					align-items: center;
					justify-content: flex-start;

					.MuiAvatar-root {
						width: 62px;
						height: 62px;
					}

					.file-name-container {
						flex: 1;
						min-width: 0;
						overflow: hidden;

						.MuiTypography-root {
							padding: 0 $padding-sm;
							box-sizing: border-box;
						}
					}

					.icon {
						&:not(:last-child) {
							margin-right: $padding-xs;
						}

						@each $name, $color in $normal-colors {
							&.#{$name} {
								color: $color;
							}
						}
					}

					a {
						color: $secondary-0;
						margin-left: auto;

						i {
							font-size: 24px;
						}
					}
				}
			}
		}
	}

	.robusta-stats-accordion {
		.MuiAccordionDetails-root {
			display: block;
			padding: $padding-sm 0 0 0;
		}

		.period-header {
			margin-bottom: $padding-sm;
		}
		.pickers-and-summary-container {
			display: flex;
			flex-wrap: wrap;

			.pickers-container {
				padding-right: $padding-md;

				.MuiTextField-root {
					max-width: 140px;
					margin-bottom: $padding-sm;

					&:first-of-type {
						margin-right: $padding-sm;
					}

					.MuiInput-root {
						margin-top: 22px;
					}
				}
			}

			.summary-container {
				display: flex;
				flex-direction: column;
				flex-grow: 1;

				.summary {
					display: flex;
					flex-wrap: wrap;
					margin-left: -$padding-xxs;
					margin-right: -$padding-xxs;

					.stats-summary-item {
						display: flex;
						flex-direction: column;
						background-color: $white;
						padding: $padding-xxs $padding-xs;
						flex-grow: 1;
						flex-basis: 0;
						margin-right: $padding-xxs;
						margin-left: $padding-xxs;
						min-width: fit-content;
						margin-bottom: $padding-xxs;
						border-radius: 3px;

						.label-container {
							color: $secondary-0;
							margin-bottom: $padding-xxs;
							.MuiTypography-root {
								color: inherit;
								white-space: nowrap;
							}
						}

						.value-container {
							display: flex;
							justify-content: space-between;

							.unit {
								color: $gray-3;
							}
						}

						&.positive {
							.value-container {
								.value {
									color: $success-0;
								}
							}
						}

						&.negative {
							.value-container {
								.value {
									color: $danger-0;
								}
							}
						}

						@each $name, $color in $normal-colors {
							&.#{$name} {
								background-color: rgba($color, 0.1);
							}
						}
					}
				}
			}
		}

		.graph-container {
			background-color: $white;
			padding: $padding-md;
			margin-top: $padding-sm;

			.graph-header {
				margin-bottom: $padding-md;
			}

			.graph {
				min-height: 285px;

				.placeholder-view {
					margin-top: -$padding-md;
				}
				@media (max-width: $md-width) {
					overflow-x: auto;
				}
			}

			&.retention {
				.recharts-responsive-container {
					.daily-stats-tooltip {
						background-color: $graybook;
						border-radius: $padding-xxs;
						border: 1px solid $gray-1;
						box-shadow: 0px 4px 4px 0px rgba($black, 0.09);
						padding: $padding-xxs;

						.MuiTypography-root {
							&.R1 {
								color: $secondary-0;
							}

							&.R3 {
								color: $warning-2;
							}

							&.R7 {
								color: $success-2;
							}

							&.MuiTypography-caption {
								color: $gray-4;
							}
						}
					}

					.recharts-surface {
						.yAxis,
						.xAxis {
							.recharts-cartesian-axis-ticks {
								text {
									@extend .MuiTypography-subtitle1;
									fill: $gray-3;
								}
							}
						}
					}
				}
			}

			&.levels {
				.graph-header {
					display: flex;
					justify-content: space-between;
					align-items: baseline;

					.select-container {
						display: flex;
						align-items: center;

						.MuiTypography-subtitle2 {
							margin-right: $padding-xs;
						}

						.MuiSelect-select {
							padding-top: 0;
							padding-bottom: 1px;
						}
					}

					@media (max-width: $md-width) {
						flex-direction: column;

						.select-container {
							margin-top: $padding-sm;
						}
					}
				}
				.recharts-responsive-container {
					.yAxis {
						.recharts-cartesian-axis-ticks {
							text {
								@extend .MuiTypography-subtitle2;
								fill: $gray-4;
							}
						}
					}
				}
			}
		}
	}
}
