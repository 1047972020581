.request-info {
	display: flex;
	flex-direction: row;
	margin-top: $padding-sm;
	padding: $padding-xs $padding-sm $padding-xs $padding-sm;
	background-color: $white;
	box-shadow: 0px 1.48178px 1.48178px rgba($black, 0.14), 0px 2.22267px 0.740891px -1.48178px rgba($black, 0.2),
		0px 0.740891px 3.70445px rgba($black, 0.12);
	border-radius: 4px;
	color: $primary-0;

	.deleted-root {
		display: flex;
		align-items: center;
	}

	i {
		font-size: 60px;
		color: $info-2;
		margin-right: $padding-xs;
	}

	.MuiTypography-body1 {
		margin-top: $padding-xs;
	}

	.MuiTypography-subtitle1 {
		color: $info-2;

		&.deleted-title {
			margin-bottom: 0;
		}
	}

	&.success {
		i {
			color: $success-0;
		}
		.MuiTypography-subtitle1 {
			color: $success-0;
		}
	}

	&.warning {
		i {
			color: $warning-0;
		}
		.MuiTypography-subtitle1 {
			color: $warning-0;
		}
	}

	&.danger {
		i {
			color: $danger-0;
		}
		.MuiTypography-subtitle1 {
			color: $danger-0;
		}
	}

	&.invert {
		background-color: $info-2;
		color: $white;

		i,
		.MuiTypography-subtitle1,
		.MuiTypography-body1 {
			color: $white;
		}

		&.success {
			background-color: $success-1;
		}

		&.warning {
			background-color: $warning-1;
		}

		&.danger {
			background-color: $danger-1;
		}
	}
}
