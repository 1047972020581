&.contacts-popover-root {
	.MuiList-root {
		background-color: $white;
		padding: 0;

		.MuiListItem-root {
			padding-left: $padding-xs;
			padding-right: $padding-xs;

			&:hover {
				background-color: $gray-0;
			}

			.MuiListItemIcon-root {
				min-width: unset;
				margin-right: $padding-xs;
				color: $secondary-0;

				.icon {
					font-size: 24px;
				}
			}
		}
	}
}
