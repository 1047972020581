.publisher-view {
	display: flex;
	flex-direction: column;
	align-items: center;

	.MuiTypography-subtitle1 {
		color: $gray-4;
	}

	.publisher-settings-container {
		max-width: fit-content;

		.status-row {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			min-width: 300px;

			.status-container {
				margin: 47px 0 10px 10px;
			}
		}
	}

	.monitoring-container {
		max-width: fit-content;

		.MuiTypography-subtitle2 {
			color: $gray-4;
		}

		.monitoring-text-container {
			display: flex;
			align-items: center;
			margin-top: $padding-md;

			&.status-container {
				margin-top: $padding-sm;
				margin-bottom: 50px;
				i {
					margin-left: $padding-sm;
				}
			}

			.MuiTypography-subtitle2 {
				display: flex;
				flex-grow: 1;
			}

			.MuiTypography-subtitle1 {
				margin-left: $padding-sm;
				min-width: 20px;
				text-align: center;

				&.positive {
					color: $success-0;
				}
				&.neutral {
					color: $warning-0;
				}
				&.negative {
					color: $danger-0;
				}
			}
		}
	}

	.removed-requests {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px;
		background-color: $white;
		border-radius: 8px;

		.standart-button {
			width: 145px;
		}
	}
}
