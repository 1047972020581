.app-container {
	.gallery {
		display: flex;
		flex-direction: column;

		@media (max-width: $md-width) {
			.label-text {
				text-align: center;
			}
		}

		.requirements-message {
			margin-top: $padding-xs;
			margin-bottom: $padding-sm;

			i {
				font-size: 22px;
				margin-right: $padding-xs;
				color: $secondary-0;
			}
		}

		.MuiList-root {
			.MuiListItem-root {
				border: 1px solid $secondary-0;
				border-radius: 8px;
				margin-bottom: 8px;
				padding: $padding-xs;

				&.placeholder {
					display: flex;
					flex-direction: column;
					align-items: center;
					.MuiListItemAvatar-root {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						height: 86px;
						width: 86px;

						.MuiCircularProgress-root {
							margin: 0;
							color: $secondary-0;
						}

						.circular-with-label {
							.label {
								.MuiTypography-root {
									color: $gray-4;
								}
							}
						}
					}

					.description {
						margin-left: 8px;

						.metadata-root {
							.MuiTypography-root {
								color: $gray-3;
								line-height: 24px;
							}
						}

						.control-buttons {
							.standart-button {
								margin-bottom: 0;
							}
						}
					}

					&.simple {
						min-height: 0;
						width: 122px;
						height: 122px;

						.MuiListItemAvatar-root {
							background-color: transparent;
							height: 100%;
							width: 100%;
						}
					}
				}

				.filename-root {
					width: 100%;
					display: flex;
					align-items: center;

					.MuiTypography-body2 {
						color: $black;
					}

					.status-container {
						height: 20px;
					}
				}

				.MuiListItemAvatar-root {
					width: 86px;
					height: 86px;
					margin-right: 16px;
					position: relative;
					background-color: $gray-1;
					border-radius: 4px;

					.MuiAvatar-root {
						width: 86px;
						height: 86px;
						border-radius: 4px;
					}

					.MuiIcon-root {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 45px;
						height: 45px;

						.icon {
							color: $secondary-0;
							font-size: 45px;
							line-height: 45px;
							width: 45px;
							height: 45px;

							&.danger {
								color: $danger-0;
							}
						}
					}

					.MuiBox-root {
						.MuiIconButton-root {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 40px;
							height: 40px;
							background-color: $gray-2;
							border-radius: 50%;
							opacity: 0.8;

							.icon {
								color: $white;
								font-size: 40px;
								line-height: 40px;
								width: 40px;
								height: 40px;

								&.icon-delit {
									font-size: 28px;
								}
							}
						}
					}
				}

				.fileinfo-root {
					width: 100%;
					display: flex;
					flex-direction: row;

					.description {
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: space-between;

						.metadata-root {
							display: flex;
							flex-direction: row;

							.icon {
								margin-right: $padding-xxs;
								color: $secondary-0;
								font-size: 24px;
							}

							.MuiTypography-body2 {
								display: flex;
								align-items: center;
								margin: 0 0 0 8px;
								color: $secondary-0;

								&.MuiTypography-colorError {
									color: $danger-0;
									cursor: pointer;

									i {
										color: $danger-0;
									}
								}
							}
						}

						.bottom-container {
							width: 100%;
							display: flex;
							flex-direction: row;
							align-items: flex-end;
							justify-content: space-between;
							margin-top: $padding-xs;

							.control-buttons {
								width: 100%;
								display: flex;
								justify-content: flex-start;

								.standart-button {
									height: 40px;
									margin: 0 0 0 8px;
								}
							}

							.attachment-metric {
								height: 40px;
								display: flex;
								flex-direction: column;
								justify-content: center;
								padding: 0 $padding-xxs;
								background-color: $white;
								border-radius: 4px;

								.metric-title {
									font-size: 12px;
									color: $secondary-0;
									line-height: initial;
								}

								.metric-value-container {
									display: flex;
									align-items: baseline;

									.metric-value {
										min-width: 5ch;
										font-size: 18px;
										font-weight: 800;
										line-height: initial;

										&.positive {
											color: $success-0;
										}

										&.negative {
											color: $danger-0;
										}

										&.intermediate {
											color: $warning-0;
										}
									}
								}
							}
						}
					}
				}

				.status-container {
					i {
						font-size: 20px;

						&.success {
							color: $success-0;
						}

						&.danger {
							color: $danger-0;
						}

						&.warning {
							color: $warning-0;
						}
					}

					.MuiCircularProgress-root {
						margin: 0;
					}
				}

				&.MuiDropzoneArea-root {
					border: 1px dashed $secondary-0;
					border-radius: 8px;
					background-color: initial;
					cursor: pointer;
					outline: 0;
					min-height: 144px;
					display: flex;
					justify-content: flex-start;

					&:hover {
						background-color: $gray-2;
						.MuiDropzoneArea-textContainer {
							.MuiDropzoneArea-text {
								color: $gray-4;
							}
							.MuiIcon-root {
								background-color: $gray-3;
							}
						}

						&.disabledDropzone {
							cursor: default;
							background-color: transparent;

							.MuiDropzoneArea-textContainer {
								.MuiDropzoneArea-text {
									color: $gray-3;
								}
								.MuiIcon-root {
									background-color: $gray-1;
								}
							}
						}
					}

					.MuiDropzoneArea-textContainer {
						display: flex;
						flex-direction: row-reverse;
						justify-content: start;
						align-items: center;

						.MuiDropzoneArea-text {
							@extend .MuiTypography-body1;
							text-align: left;
							color: $gray-3;
						}

						.MuiIcon-root {
							width: 40px;
							height: 40px;
							border-radius: 50%;
							background-color: $gray-1;
							margin-right: 20px;

							.icon {
								color: $white;
								font-size: 22px;
								line-height: 40px;
								width: 40px;
								height: 40px;
							}
						}
					}
				}

				&.Mui-error {
					border-color: $error-main;
				}
			}
		}

		&.simple {
			max-width: 122px;
		}

		&.vertical {
			.MuiList-root {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				margin: -$padding-xs;

				.MuiListItem-root {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					margin: 8px;
					padding: $padding-xs;
					height: 144px;
					width: calc(100% / 3 - 16px);

					@media (max-width: $xl-width) {
						width: calc(50% - 16px);
					}

					@media (max-width: $md-width) {
						width: 100%;
						margin: $padding-sm 0 $padding-sm 0;
					}

					&.simpleGallery {
						padding: 0;
						border: none;
						min-height: 0;
						max-width: 122px;
						width: 122px;
						height: 122px;
						flex-grow: 0;

						.MuiListItemAvatar-root {
							width: 122px;
							height: 122px;
							border-radius: 6px;

							.MuiAvatar-root {
								width: 122px;
								height: 122px;
								border-radius: 6px;
							}
						}
					}

					.MuiListItemAvatar-root {
						margin: 0;
					}

					&.MuiDropzoneArea-root {
						max-width: none;
						flex-grow: 1;
						padding: $padding-sm;
						overflow: hidden;

						&.simpleDropzone {
							min-height: 0;
							width: 122px;
							height: 122px;
							padding: $padding-xs;

							.MuiDropzoneArea-textContainer {
								.MuiIcon-root {
									margin: 0;
									i {
										font-size: 22px;
									}
								}

								.MuiDropzoneArea-text {
									@extend .MuiTypography-caption;
									color: $gray-3;
									margin-top: $padding-xs;
									margin-bottom: 0;
								}
							}
						}

						.MuiDropzoneArea-textContainer {
							flex-direction: column-reverse;
							align-items: center;
							align-self: center;
							justify-content: center;
							flex-grow: 1;

							.MuiIcon-root {
								margin: 0;
							}

							.MuiDropzoneArea-text {
								text-align: center;
								margin: $padding-xs 0;
							}
						}
					}
				}
			}
		}
	}

	.icon-placeholder {
		width: 100%;
		max-width: 122px;
		height: 122px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin-top: 8px;
		margin-bottom: $padding-sm;
		border: 1px dashed $secondary-0;
		box-sizing: border-box;
		border-radius: 6px;
		color: $gray-3;

		.icon {
			font-size: 42px;
			color: $gray-2;
			margin-bottom: $padding-xs;
		}
	}

	.gallery-placeholder {
		width: 100%;
		height: 228px;
		display: flex;
		justify-content: center;
		align-items: center;
		align-self: center;
		margin-top: $padding-sm;

		.MuiTypography-root {
			color: $gray-3;
		}

		border: 1px dashed $secondary-0;
		box-sizing: border-box;
		border-radius: 7px;
	}
}

.gallery-dialog-player {
	.MuiDialog-paper {
		position: relative;
		width: 80vw;
		height: 80vh;
		max-width: 80vw;
		max-height: 80vh;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		padding: 40px;

		.player-container {
			position: relative;
			width: 100%;
			flex: 1;
			background-color: $black;
			border-radius: 5px;
			overflow: hidden;

			.player {
				position: absolute;
				top: 0;
				left: 0;
			}
		}

		.controls-container {
			flex: 0;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			margin-top: 30px;

			.genre-checkbox {
				height: 43px;
				margin: 33px $padding-xs 15px 0;
			}

			.genre-list {
				width: 200px;
				margin-right: $padding-xs;
			}
		}

		.close-button {
			position: absolute;
			top: 16px;
			right: 16px;
			width: 24px;
			height: 24px;
			color: $black;
			font-size: 24px;
		}
	}
}

.gallery-dialog-crop {
	.MuiDialog-paper {
		position: relative;
		border-radius: 8px;

		display: flex;
		flex-direction: column;
		align-items: center;
		padding: $padding-sm $padding-md;

		.MuiTypography-colorSecondary {
			&.text-warning {
				color: $warning-0;
			}
		}

		.MuiTypography-h5 {
			margin-bottom: $padding-sm;
		}

		.content {
			display: flex;
			flex-direction: column;

			.MuiTypography-paragraph {
				margin-bottom: $padding-xs;
			}

			.requirements-message {
				margin-top: $padding-sm;
			}
		}

		.video-crop {
			padding: 0 $padding-sm;
			margin-bottom: $padding-sm;
			width: 100%;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.ReactCrop {
			margin: $padding-sm 0 $padding-xs;

			& > div {
				width: fit-content;
			}
			.content {
				flex-grow: 1;

				video {
					width: unset !important;
					height: unset !important;
					max-height: 60vh;
					display: block;
					max-width: 100%;
				}
			}

			&.video-invisible {
				.content {
					visibility: hidden;
				}
			}

			.loading-message {
				display: flex;
				flex-direction: column;
				position: absolute;
				align-items: center;
				justify-content: center;
				left: 30%;
				top: 30%;
				width: 40%;
				height: 40%;
				min-width: 100px;
				min-height: 50px;

				.MuiCircularProgress-root {
					color: $gray-3;
					margin-bottom: $padding-xs;
				}
			}
		}

		.icon-info {
			font-size: 22px;
			color: $secondary-0;
			margin-right: $padding-xs;
		}

		.standart-button {
			width: 180px;
			padding: 15px 25px;
			margin-top: $padding-sm;

			.MuiCircularProgress-root {
				margin-left: -25px;
				margin-right: $padding-xxs;
			}
		}

		.close-button {
			position: absolute;
			top: 16px;
			right: 16px;
			width: 24px;
			height: 24px;
			font-size: 24px;
			opacity: 0.7;
		}

		.video-controls-container {
			width: 100%;
			display: flex;
			align-items: flex-start;
			margin-top: $padding-md;

			.button-group {
				display: flex;
				height: $padding-md;
				margin-right: $padding-xxs;

				.MuiIconButton-root {
					padding: $padding-xxs;
					background-color: $secondary-0;
					color: $white;
					border-radius: 3px;
					margin-left: $padding-xxs;
					&:first-of-type {
						margin-left: 0;
					}

					&:first-of-type,
					&:last-of-type {
						padding: 0;
					}
				}
			}

			.video-crop-slider {
				flex-basis: 100%;
				line-height: 0;
				padding: 0 12px 24px;

				.progress-root {
					position: relative;

					.progress-indicator {
						position: absolute;
						z-index: 1;
						pointer-events: none;
						display: inline-block;
						width: 2px;
						height: $padding-md;
						background-color: $success-0;
						transition: left 0.1s linear;

						&:before {
							content: "";
							position: absolute;
							border: solid 4px transparent;
							border-top-color: $success-0;
							transform: translate(-3px, -3px);
						}

						&:after {
							color: $gray-4;
							font-size: 14px;
							font-family: "Open Sans", "Helvetica", "Arial", sans-serif;
							font-weight: 400;
							line-height: 19px;
							content: attr(data-progress);
							white-space: nowrap;
							position: absolute;
							bottom: -$padding-xxs;
							transform: translate(-50%, 100%);
						}
					}
				}

				.MuiSlider-root {
					padding: 0;
					&,
					.MuiSlider-rail,
					.MuiSlider-track,
					.MuiSlider-thumb {
						height: $padding-md;
					}

					&,
					.MuiSlider-rail,
					.MuiSlider-track {
						border-radius: 3px;
					}

					.MuiSlider-rail {
						color: $secondary-2;
						margin-left: -12px;
						padding: 0 12px;
					}

					.MuiSlider-track {
						color: $info-0;
					}

					.MuiSlider-thumb {
						margin: 0;
						border-radius: 3px 0 0 3px;
						width: unset;
						color: $secondary-0;
						transform: translateX(-50%);

						&.Mui-focusVisible,
						&.MuiSlider-active,
						&:hover {
							box-shadow: 0px 0px 0px 4px rgba(63, 81, 181, 0.16);
						}

						i {
							color: $white;
							font-size: 24px;
						}

						&:last-of-type {
							border-radius: 0 3px 3px 0;

							.icon {
								transform: rotate(180deg);
							}
						}

						.value-label {
							position: absolute;
							box-sizing: border-box;
							top: -$padding-xs;
							transform: translateY(-100%);
							background-color: $info-2;
							border-radius: 12px;
							padding: 0 $padding-xs;

							&:after {
								content: "";
								position: absolute;
								border: solid 4px transparent;
								border-top-color: $info-2;
								bottom: 0;
								left: 50%;
								transform: translate(-50%, 100%);
							}

							.MuiTypography-root {
								color: $white;
							}
						}
						&:nth-of-type(odd) {
							.value-label {
								right: 0;
								&:after {
									left: unset;
									transform: translate(-12px, 100%);
								}
							}
						}

						&:last-of-type {
							.value-label {
								left: 0;
								&:after {
									left: 0;
									transform: translate(12px, 100%);
								}
							}
						}

						&:after {
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
						}
					}
				}
			}
		}

		.video-crop.selection-at-boundaries {
			.video-controls-container {
				.video-crop-slider {
					.value-label {
						background-color: $warning-0;

						&:after {
							border-top-color: $warning-0;
						}
					}
				}
			}
		}
	}
}
