.MuiPaper-root {
	color: $black;
	background-color: $gray-0;
	&.MuiPaper-rounded {
		border-radius: 6px;
	}
	&.MuiAppBar-colorTransparent {
		background-color: transparent;
	}
	&.MuiMenu-paper {
		background-color: $white;
	}
}

.MuiMenu-list {
	padding: 8px;
	.MuiListItem-root {
		padding: 6px 8px;

		&.Mui-selected,
		&.Mui-selected:hover {
			background-color: $gray-0;
			.MuiTypography-body1 {
				color: $success-0;
			}
		}

		&.Mui-focusVisible,
		&:hover {
			background-color: rgba($gray-0, 0.5);
		}
		.MuiCheckbox-root {
			margin: -6px -8px;
		}
	}
}
