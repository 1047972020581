.circular-with-label {
	position: relative;
	display: inline-flex;

	.label {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $gray-4;
	}
}
