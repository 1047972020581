$text-colors: (
	"primary": $primary-2,
	"secondary": $secondary-2,
	"info": $info-2,
	"success": $success-2,
	"warning": $warning-2,
	"danger": $danger-2,
	"rose": $rose-2,
	"white": $white,
	"twitter": $validation,
	"telegram": $telegram,
	"facebook": $facebook,
	"google": $google,
	"linkedin": $linkedin,
	"pinterest": $pinterest,
	"youtube": $youtube,
	"tumblr": $tumblr,
	"github": $github,
	"behance": $behance,
	"dribbble": $dribbble,
	"reddit": $reddit,
	"instagram": $instagram,
	"apple": $apple,
	"new": $new,
	"validation": $validation,
	"running": $running,
	"transparent": none,
);

$card-colors: (
	"primary": $primary-1,
	"secondary": $secondary-1,
	"info": $info-1,
	"success": $success-1,
	"warning": $warning-1,
	"danger": $danger-1,
	"rose": $rose-1,
	"twitter": $twitter,
	"telegram": $telegram,
	"facebook": $facebook,
	"google": $google,
	"linkedin": $linkedin,
	"pinterest": $pinterest,
	"youtube": $youtube,
	"tumblr": $tumblr,
	"behance": $behance,
	"dribbble": $dribbble,
	"reddit": $reddit,
	"instagram": $instagram,
	"github": $github,
	"apple": $apple,
	"validation": $validation,
	"new": $new,
	"running": $running,
);

.notification-card-container {
	margin: $padding-xs 0;
	display: flex;
	justify-content: space-between;
	padding: $padding-sm;
	margin-bottom: $padding-sm;

	@media (max-width: $sm-width) {
		flex-direction: column;
	}

	.icon-container {
		display: block;
		width: 50px;
		height: 50px;
		text-align: center;

		.icon {
			font-size: 40px;
			color: $white;
			line-height: 50px;
		}
	}

	.MuiCardHeader-root {
		padding: 0;
		flex-grow: 1;
		align-items: flex-start;
		padding-right: $padding-sm;

		@media (max-width: $sm-width) {
			flex-direction: column;
			padding-right: unset;
		}

		.MuiCardHeader-action {
			.MuiButtonBase-root {
				color: $gray-2;
				font-size: 12px;
			}
		}

		.MuiCardHeader-title {
			font-size: 17px;
			line-height: 22px;
			letter-spacing: -0.41px;
			font-weight: bold;
			color: $white;
			padding-bottom: $padding-xs;

			@media (max-width: $sm-width) {
				padding-top: $padding-xs;
			}
		}

		.MuiCardHeader-subheader {
			font-size: 17px;
			line-height: 22px;
			letter-spacing: -0.41px;
			color: $white;
		}
	}

	.MuiCardActions-root {
		flex-direction: column;
		justify-content: flex-start;
		padding: 0;

		@media (max-width: $sm-width) {
			margin-top: $padding-sm;
		}

		.close-button {
			color: $white;
			align-self: flex-end;
			font-size: $body1-size;
			margin-bottom: $padding-xs;
		}

		@each $name, $color in $text-colors {
			.text-color-#{$name} {
				color: $color;
			}
		}
	}
	@each $name, $color in $card-colors {
		&.bg-#{$name} {
			background-color: $color;
		}
	}
}
