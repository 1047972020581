.footer {
	flex-direction: row;
	.container {
		display: flex;
		flex-grow: 1;
		align-items: center;
		max-width: $xl-width;

		@media (max-width: $xl-width) {
			flex-direction: column;
		}

		@media (max-width: $sm-width) {
			.button-container {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
			}
		}

		.transparent {
			color: $gray-4;
		}

		.MuiButton-root {
			padding-left: $padding-sm;
			padding-right: $padding-sm;
		}

		.social-copyright-container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			flex-grow: 1;
			justify-content: center;

			.social {
				display: flex;
				flex-grow: 1;
				justify-content: center;

				.MuiButton-root {
					margin-left: $padding-xxs;
					margin-right: $padding-xxs;
					.icon {
						font-size: 26px;
						padding-bottom: 2px;
					}
				}
			}
		}
		.copyrights {
			padding: $padding-xs;
			align-self: center;
			text-align: center;
		}
	}

	&.white {
		.transparent {
			color: $white;
		}
		.copyrights {
			color: $white;
		}
	}
}
