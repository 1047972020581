.doc-view {
	p {
		margin-bottom: $padding-xs;
	}

	h3 {
		margin-bottom: $padding-md;
	}

	h5 {
		margin-top: $padding-sm;
		margin-bottom: $padding-sm;
	}

	ol {
		counter-reset: item;
		padding: 0;
		list-style: none;

		> li {
			counter-increment: item;

			&::before {
				content: counters(item, ".") ".";
				padding-right: $padding-xxs;
				font-size: $body1-size;
				line-height: $body1-line;
				font-weight: 700;
				float: left;
			}

			&.header {
				&::before {
					font-size: $h5-size;
					font-weight: 700;
					line-height: $h5-line;
					font-family: $font-title;
				}
			}
		}

		&.alpha-list {
			counter-reset: alpha;
			& > li {
				counter-increment: alpha;

				&::before {
					content: counter(alpha, lower-alpha) ")";
				}
			}
		}
	}

	table {
		margin-bottom: $padding-xs;
		thead {
			tr {
				background-color: $gray-2;
				th {
					font-weight: bold;
				}
			}
		}
		tbody {
			tr {
				&:nth-of-type(odd) {
					background-color: $gray-1;
				}

				&:nth-of-type(even) {
					background-color: $white;
				}

				td {
					vertical-align: top;
				}
			}
		}
	}

	.link {
		color: $secondary-0;

		i {
			font-size: 20px;
			margin-right: $padding-xxs;
			vertical-align: text-top;
		}

		&:hover {
			color: $secondary-2;
		}
	}
}
